import React from 'react'
import Blog from '../components/Blog'

const BlogPage = () => {
  return (
    <Blog></Blog>
  )
}

export default BlogPage
