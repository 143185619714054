import styled from "styled-components";

export const Logo=styled.img`
    height:40px;
    @media screen and (max-width:960px){
        height:25px;
    }
    
    @media screen and (max-width:480px){
        height:20px;
    }
`;