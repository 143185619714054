import React from 'react'
import HowDoesItWork from '../components/Solutions/HowDoesItWorkSection';
import HowToContact from '../components/Solutions/HowtoContactSection';
import SolutionHero from '../components/Solutions/SolutionHero';
import Values from '../components/Solutions/ValuesSection';
import WhatisPrizy from '../components/Solutions/WhatisPrizySection';
import PrizyProSection from '../components/Solutions/PrizyProSection';


const SolutionPage=()=> {

  return (
    <>
        <SolutionHero></SolutionHero>
        <Values></Values> 
        <WhatisPrizy></WhatisPrizy>
        <HowDoesItWork></HowDoesItWork>
        <PrizyProSection></PrizyProSection>
        <HowToContact></HowToContact>
    </>
  )
}

export default SolutionPage;
