import styled from "styled-components";

import {FaDigitalTachograph} from "react-icons/fa";
import {BsCreditCard2Front} from "react-icons/bs";
import {BiStore} from "react-icons/bi";
import {SiHandshake} from "react-icons/si";

import {GiSellCard} from "react-icons/gi";
import {FaSellsy} from "react-icons/fa";
import {TbTruckOff} from "react-icons/tb";
import {MdOutlinePeopleAlt} from "react-icons/md";
import { commonIconStyle } from "../SharedComponents/SharedComponents";


export const Icon1=styled(FaDigitalTachograph)`
    ${commonIconStyle}
`;
export const Icon2=styled(BsCreditCard2Front)`
    ${commonIconStyle}
`;
export const Icon3=styled(BiStore)`
    ${commonIconStyle}
`;
export const Icon4=styled(SiHandshake)`
    ${commonIconStyle}
`;
export const Icon5=styled(GiSellCard)`
    ${commonIconStyle}
`;
export const Icon6=styled(FaSellsy)`
    ${commonIconStyle}
`;
export const Icon7=styled(TbTruckOff)`
    ${commonIconStyle}
`;
export const Icon8=styled(MdOutlinePeopleAlt)`
    ${commonIconStyle}
`;



export const Img=styled.img`
    width:80%;
    height:100%;

`;
