import styled from "styled-components";

/*Containers*/
export const Container=styled.div`
    position:relative;
    height:fit-content;
    padding: 1.2rem 3%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background: ${props => props.color};
    @media screen and (max-width:960px){
      padding:2% 3%;
  } 
`;

export const SubTitle=styled.p`
    color: ${({white})=>(white?'#fff':'#0a0a0a')};
    font-size:1.1rem;
    font-weight:400;
    text-align:${({center})=>(center?'center':'left')};
    line-height:1.5;
    
    @media screen and (max-width:480px){
        font-size:0.8rem;
    }
`;
export const Span=styled.span`
    color: ${({white})=>(white?'#fff':'#0a0a0a')};
    font-size:1.1rem;
    font-weight:500;
    text-align:${({center})=>(center?'center':'left')};
    line-height:1.5;
    
    @media screen and (max-width:480px){
        font-size:0.8rem;
    }
`;
