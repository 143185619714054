import styled,{css,keyframes} from "styled-components";



export const animateglow = keyframes`
    0% {
      opacity: 0;
      filter: brightness(3) saturate(3);
      transform: scale(0.8, 0.8);
    }
    100% {
      opacity: 1;
      filter: brightness(1) saturate(1);
      transform: scale(1, 1);
    }
`;

export const StepsWrapper=styled.div`

    margin:5% 0;
    display:grid;
    grid-template-columns: repeat(6, 150px);
    grid-template-areas: "box4 box4 box5 box5 box6 box6" "empt box1 box1 box2 box2 emp2";
    grid-template-rows: 1fr 1fr;
    align-items:start;
    grid-gap:1rem;

    @media screen and (max-width:990px){
        grid-template-columns: repeat(4, 150px);;
        grid-template-areas: "box4 box4 box5 box5" " box6 box6 box1 box1" "empt box2 box2 emp2";
        grid-template-rows: 1fr 1fr 1fr;
    }
    
    @media screen and (max-width:768px){
        grid-template-columns: 1fr;
        grid-template-areas: "box4 box4" "box5 box5" "box6 box6" "box1 box1" "box2 box2";
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    }
`;



export const StepCard=css`
    
    background:#fff;
    color:#0a0a0a;

    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:start;

    transition: all 0.2 ease-in-out;
    animation-name: ${animateglow};
    animation-timing-function: ease;
    animation-duration:1s;

    height:fit-content;
    width:300px;

    @media screen and (max-width:480px){
        width:100%;
    }

    &:hover{
        transform: scale(1.05);
        transition: all 0.2 ease-in-out;
        cursor:pointer;
    }


`;
export const StepCard1=styled.div`
    ${StepCard}
    grid-area: box4;

`;
export const StepCard2=styled.div`
    ${StepCard}
    grid-area: box5;
`;

export const StepCard3=styled.div`
    ${StepCard}
    grid-area: box6;
`;

export const StepCard4=styled.div`
    ${StepCard}
    grid-area: box1;
`;

export const StepCard5=styled.div`
    ${StepCard}
    grid-area: box2;
`;



export const StepNumber=styled.h2`
    font-size:2rem;
    font-weight:600;
    margin-bottom:10px;
    @media screen and (max-width:960px){
        font-size:1.2rem;
    }
    
    @media screen and (max-width:480px){
        font-size:1rem;
    }
    

`;

export const Steptext=styled.p`
    font-size:1rem;
    font-weight:400;
    margin-bottom:10px;
   
    

`;

export const StepImage=styled.img`
    width:100%;
    margin-bottom:10%;
    border-radius:5px;
`;





