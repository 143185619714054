import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import { EffectCreative } from "swiper";
import { useTranslation } from "react-i18next";
import "./style.css";
import { ReviewsWrapper } from "./ReviewsElements";
import {
  LeftRightContainer,
  TextColumn,
  ImageColumn,
  SubTitle,
  H1,
} from "../../SharedComponents/SharedComponents";
import { SliderData } from "./SliderData";
import ReviewCard from "./ReviewCard";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactGA from "react-ga4";

const ReviewsSection = () => {

  const { t } = useTranslation();
 
  return (
    <LeftRightContainer color="#ffe6cc">
      <TextColumn>
        <H1>{t('reviews')}</H1>
        <SubTitle>
        {t('reviews-subtitle')}
        </SubTitle>
      </TextColumn>

      <AnimationOnScroll
        animateIn="animate__fadeInRight"
        delay={2}
        animateOnce={1}
      >
        <ImageColumn>
          <ReviewsWrapper>
            <Swiper
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: ["-120%", 0, -500],
                },
                next: {
                  shadow: true,
                  translate: ["120%", 0, -500],
                },
              }}
              modules={[EffectCreative]}
              className="mySwiper2"
              onSlideChange={ReactGA.event({
                category: "Reviews Section From Landing Page",
                action: "Review viewed",
              })}
            >
              {SliderData.map((data, index) => {
                return (
                  <SwiperSlide>
                    <ReviewCard
                      name={data.name}
                      role={data.role}
                      review={data.review}
                      picture={data.picture}
                      entreprise={data.entreprise}
                    ></ReviewCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </ReviewsWrapper>
        </ImageColumn>
      </AnimationOnScroll>
    </LeftRightContainer>
  );
};

export default ReviewsSection;
