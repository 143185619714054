import React from 'react'
import ContactSection from '../components/ContactUs';
import FAQSection from '../components/FAQ';


const Contact=()=> {

  return (
    <>
      <ContactSection></ContactSection>
      <FAQSection></FAQSection>
    </>
  )
}

export default Contact;