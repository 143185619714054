import React from "react";
import SolutionSection from "../components/LandingPage/SolutionSection";
import OffersSection from "../components/LandingPage/OffersSection";
import ReviewsSection from "../components/LandingPage/ReviewsSection";
import NumberSection from "../components/LandingPage/NumbersSection";
import BlogSection from "../components/LandingPage/BlogSection";
import ClientSection from "../components/LandingPage/ClientSection";
import HeroSection from "../components/LandingPage/HeroSection";
import AvantagesSection from "../components/LandingPage/AdvantagesSection";
import NewsLetter from "../components/LandingPage/NewsLetter";
import DownloadAppSection from "../components/MobileApp/DownloadApp";
import { Annonce } from "../components/LandingPage/Annonce";

const Home = () => {
  return (
    <>
      <Annonce></Annonce>
      <HeroSection></HeroSection>
      <ClientSection></ClientSection>
      <SolutionSection></SolutionSection>
      <OffersSection></OffersSection>
      <NumberSection></NumberSection>
      <AvantagesSection></AvantagesSection>
      <DownloadAppSection></DownloadAppSection>
      <ReviewsSection></ReviewsSection>
      <BlogSection></BlogSection>
      <NewsLetter></NewsLetter>
    </>
  );
};

export default Home;
