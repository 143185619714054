import React from "react";
import { useTranslation } from "react-i18next";
import AvantageOneTwo from "../components/PrizyPro/AvantageOneTwo";
import AvantageThree from "../components/PrizyPro/AvantageThree";
import PrizyProHero from "../components/PrizyPro/PrizyProHeroSection";
import JoinUsBanner from "../components/SharedComponents/JoinUsBanner";

const PrizyPro = () => {
  const { t } = useTranslation();
  return (
    <>
      <PrizyProHero></PrizyProHero>
      <AvantageOneTwo></AvantageOneTwo>
      <AvantageThree></AvantageThree>
      <JoinUsBanner
        pink={1}
        sousTitre={t("integrateprizypro")}
        buttonText={t("join")}
      ></JoinUsBanner>
    </>
  );
};

export default PrizyPro;
