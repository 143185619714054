import styled from "styled-components";
import { cardsharedStyles, cardsWrappersharedStyles,hoverCardStyle } from "../SharedComponents/SharedComponents";
import {RiTimerLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'




export const BlogButton=styled(Link)`

    background: #b0e0d6;
    white-space:nowrap;

    padding:0.5rem 1rem;
    margin:1rem 0;

    color:#0a0a0a;
    font-size:0.9rem;

    font-weight: 500;
    text-align:center;
    cursor:pointer;
    text-decoration:none;
    display:flex;
    justify-content:center;
    align-items:center;

    transition:all 0.2s ease-in-out;

    @media screen and (max-width:960px){
   
    }
    @media screen and (max-width:480px){
   
    }
  
    &:hover{
        transform: scale(1.1);
    }
`;

export const BlogBtnWrapper=styled.div`
  display:flex;
  justify-content: flex-start;
  align-items:center;
  
`;




export const ArticleSubTitle=styled.h2`
    color:#8eccbe;
    font-size:2rem;
    font-family: "Kurye"; 
    font-weight: 700;
    text-align:left;
    margin-bottom:1rem;
    margin-top:1rem;

    @media screen and (max-width:960px){
    font-size:1.5rem;
    }

    @media screen and (max-width:480px){
    font-size:1.3rem;
    }

`;
export const ArticleParagraph=styled.p`
    
    color: ${({white})=>(white? '#fff;' :'#0a0a0a;')}
    font-size:1rem;
    font-weight:400;
    line-height:1.5;
    margin-bottom:1rem;

    @media screen and (max-width:480px){
        font-size:0.8rem;
    }

`;
export const Articleli=styled.li`
    color: #0a0a0a;
    font-size:1rem;
    line-height:1.5;
    margin: 0 0 1rem 0;
    margin-left: 1rem;
    @media screen and (max-width:480px){
        font-size:0.8rem;
    }
    
`;
export const ArticleContentContainer=styled.div`
    margin: 0 10%;
    padding: 5%;
    border : 1px solid #d6d6d6;
    border-radius:0 0 5px 5px;
    
    
    @media screen and (max-width:960px){
        margin: 0;
        padding: 5% 0;
        border : none;
        box-shadow: none;
        border-radius: 5px;
    }
    
`;
export const ArticleUl=styled.ul``;


export const Timericon=styled(RiTimerLine)`
    color: #fff;
    font-size:1rem;
    margin-right: 1rem;
    margin-bottom:1rem;

`;

export const TimerWrapper=styled.div`
    background:transparent;
    height:fit-content;
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items:center;

`;

export const SliderCardwrapper=styled.div`
    background-image: url('${props => props.img}');
    background-size: cover;
    border: 1px solid #0a0a0a;
    border-radius:2px 2px 0 0;
    padding:2%;
    margin: 2% 10%;
    margin-bottom: 0;
    height:fit-content;
    display:grid;
    grid-auto-columns:minmax(auto,1fr);
    align-items:center;
    justify-content:center;
    grid-template-areas:'col1 col2';
    width:80%;

    @media screen and (max-width:960px){
        grid-template-areas:'col1 col1' 'col2 col2';
        border-radius:2px;
        margin-top: 2%;
        margin-bottom: 2%;
        width:100%;
    }
    
`;



export const BlogSectionWrapper=styled.div`
    ${cardsWrappersharedStyles};
`;


export const BlogSectionCard=styled.div`
    ${cardsharedStyles};
    ${hoverCardStyle};
    
    min-height:300px;
    max-width:500px;

`;


export const BlogSectionTitle=styled.h2`
    font-size:1.5rem;
    font-weight:700;
    color:#fff;
    margin-bottom:10px;
    
    @media screen and (max-width:480px){
        font-size:1rem;
    }
    

`;

export const BlogSectionSubTitle=styled.p`
    font-size:1rem;
    font-weight:400;
    color:#fff;
    margin-bottom:10px;
    @media screen and (max-width:480px){
        font-size:0.8rem;
    }
    
     

`;





