import styled,{css, keyframes} from "styled-components";
import {MdOutlineStorefront,MdOutlineAccountBalanceWallet} from "react-icons/md";
import { FaMoneyCheck } from "react-icons/fa";


export const animateglow = keyframes`
    0% {
      opacity: 0;
      filter: brightness(3) saturate(3);
      transform: scale(0.8, 0.8);
    }
    100% {
      opacity: 1;
      filter: brightness(1) saturate(1);
      transform: scale(1, 1);
    }
`;

export const ValueWrapper=styled.div`

    margin:2rem 2rem;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items:center;
    grid-gap:1rem;

    @media screen and (max-width:1160px){
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width:749px){
        grid-gap:0.5rem;
        grid-template-columns: 1fr;
    }
    
`;



export const ValueCard=styled.div`
    
    background:#fff;
    border-radius:2px;
    border:1px solid #eaeaea;
    color:#0a0a0a;

    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:start;

    transition: all 0.2 ease-in-out;
    animation-name: ${animateglow};
    animation-timing-function: ease;
    animation-duration:1s;

    height:fit-content;
    min-height:380px;
    width:400px;
    padding:10%;

    @media screen and (max-width:1160px){
        min-height:400px;
        width:350px;
    }
    @media screen and (max-width:749px){
        min-height:250px;
        width:100%;
        padding:10%;
    }

    &:hover{
        transform: scale(1.05);
        transition: all 0.2 ease-in-out;
        cursor:pointer;
        border:1px solid #0a0a0a;
        
    }



    
    
`;

const commonIconStyle=css`
    height:2.5rem;
    width:2.5rem;
    color:#0a0a0a;
    
    @media screen and (max-width:960px){
        height:2rem;
        width:2rem;
    }
    
    @media screen and (max-width:480px){
        height:2rem;
        width:2rem;
    }
`;

export const IconContainer=styled.div`
    background: ${props=> props.color};
    border-radius:5px;
    padding:5%;
    margin-bottom:1rem;
    height:fit-content;
    width: fit-content;
    display:flex;
    justify-content:center;
    align-items:center;
`;

export const CardTransactions=styled(FaMoneyCheck)`
    ${commonIconStyle}
    
`;
export const CardStores=styled(MdOutlineStorefront)`
    ${commonIconStyle}


`;
export const CardMoney=styled(MdOutlineAccountBalanceWallet)`
    ${commonIconStyle}


`;


export const CardH2=styled.h2`
    font-size:1.5rem;
    font-weight:500;
    margin-bottom:10px;
    @media screen and (max-width:960px){
        font-size:1.2rem;
    }
    
    @media screen and (max-width:480px){
        font-size:1rem;
    }
    

`;

export const CardP=styled.p`
    font-size:1rem;
    font-weight:400;
    margin-bottom:10px;
    @media screen and (max-width:960px){
        font-size:0.9rem;
    }
    
`;







