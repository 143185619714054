import React from "react";
import {
  ArticleContentContainer,
  ArticleParagraph,
  ArticleSubTitle,
  ArticleUl,
  Articleli,
} from "./BlogElements";
import { Container } from "../SharedComponents/SharedComponents";
import ArticleBanner from "./ArticleBanner";
import incentive from "../../assets/incentivebanner.png";
import ReactGA from "react-ga4";

const Article2 = () => {
  ReactGA.event({
    category: "Incentive",
    action: "Blog article",
  });
  return (
    <Container>
      <ArticleBanner
        title={"Incentive"}
        date={"14 Décembre 2022"}
        time={"10"}
        img={incentive}
      ></ArticleBanner>
      <ArticleContentContainer>
        <ArticleSubTitle>Qu'est-ce qu'un programme incentive?</ArticleSubTitle>
        <ArticleParagraph>
          Un programme incentive est l'utilisation structurée de récompenses et
          de reconnaissance pour motiver le comportement d'un groupe spécifique
          de personnes.
        </ArticleParagraph>
        <ArticleParagraph>
          Les incentives sont un outil de modification du comportement qui peut
          être utilisé pour diverses raisons. En attribuant des récompenses pour
          avoir démontré les comportements attendus, ces comportements seront
          répétés plus souvent.
        </ArticleParagraph>
        <ArticleParagraph>
          Les programmes d'incentive utilisent généralement des récompenses
          autres que le cash, au lieu de commissions ou de compensation en
          espèces. En effet, les récompenses non monétaires sont plus motivantes
          et plus efficaces pour un changement de comportement à long terme. Ils
          ont une valeur sociale et émotionnelle que le cash n'a pas.
        </ArticleParagraph>

        <ArticleSubTitle>Exemples de programmes d’incentives</ArticleSubTitle>
        <ArticleParagraph>
          Les entreprises utilisent des incitations pour aligner leur
          comportement sur les objectifs de leur entreprise. Voici des exemples
          de programmes d'incitation:
        </ArticleParagraph>
        <ArticleUl>
          <Articleli>Incentive pour les commerciaux</Articleli>
          <Articleli>Incentive pour employés</Articleli>
          <Articleli>Incentive à la clientèle</Articleli>
          <Articleli>Incentives de canal de distribution</Articleli>
          <Articleli>Incentives des concessionnaires</Articleli>
        </ArticleUl>

        <ArticleSubTitle>
          Quels sont les différents types de récompenses?
        </ArticleSubTitle>
        <ArticleParagraph>
          Les types courants de récompenses incitatives comprennent:
        </ArticleParagraph>
        <ArticleUl>
          <Articleli>
            Challenge cadeaux basés sur un système de points.
          </Articleli>
          <Articleli>Voyage de groupe.</Articleli>
          <Articleli>
            Récompenses des cartes-cadeaux, bon d’achat et carte de débit.
          </Articleli>
          <Articleli>Reconnaissance.</Articleli>
        </ArticleUl>

        <ArticleSubTitle>
          Comment mettre en place un programme d’incentive?
        </ArticleSubTitle>
        <ArticleParagraph>
          En règle générale, les programmes d'incentives modernes s'appuient sur
          des interfaces en ligne et des plates-formes numériques pour attribuer
          des points, obtenir des récompenses et communiquer avec les
          participants. Cela améliore l'évolutivité et l'accessibilité de ces
          programmes et permet aux entreprises de suivre plus facilement les
          données et de gérer les rachats.
        </ArticleParagraph>
        <ArticleParagraph>
          S'il est possible de gérer manuellement un programme d'incitation,
          cela n'est généralement faisable que pour les très petites
          organisations. En travaillant avec une plate-forme digitale
          d’incentive et en gérant leur programme via un portail
          d'administration en ligne, 70% des entreprises passent moins de deux
          heures par semaine à gérer leur programme d'incentive.
        </ArticleParagraph>

        <ArticleSubTitle>
          Prizy : Un outil qui vous facilite vos programmes d’incentive
        </ArticleSubTitle>
        <ArticleParagraph>
          Nous sommes heureux de vous annoncer notre dernière innovation Prizy
          ex Fixit +: le 1er bon d'achat 100% digital, la solution idéale pour
          enchanter vos employés et clients à n'importe quelle occasion.
        </ArticleParagraph>
        <ArticleUl>
          <Articleli>
            Simplicité : Distribuez vos bons d'achat en 1 clic à vos
            bénéficiaires
          </Articleli>
          <Articleli>
            Flexibilité : Vos bénéficiaires peuvent utiliser leur bon d'achat en
            magasin et en ligne.
          </Articleli>
          <Articleli>
            Satisfaction : Vos bons d'achat sont également utilisables jusqu'au
            dernier centime.
          </Articleli>
          <Articleli>
            Transparence : Suivez en temps réel la consommation de vos bons
            d'achat.
          </Articleli>
        </ArticleUl>
        <ArticleParagraph>Plus d'informations sur : Prizy.co</ArticleParagraph>
      </ArticleContentContainer>
    </Container>
  );
};

export default Article2;
