import React from 'react'
import { useTranslation } from "react-i18next";
import PrizyPartnersHero from '../components/Partners/PartnersHero';
import PartnersList from '../components/Partners/PartnersList';
import JoinUsBanner from '../components/SharedComponents/JoinUsBanner';

const Partners=()=> {
  const { t } = useTranslation();

  return (
    <>
        <PrizyPartnersHero></PrizyPartnersHero>
        <PartnersList></PartnersList>
        <JoinUsBanner pink={0} sousTitre={t('integratepartners')} buttonText={t('devenirpartenaire')}></JoinUsBanner>
    </>
  )
}

export default Partners;