import React from "react";
import { useTranslation } from "react-i18next";
import img from "../../../assets/partners.png";
import { Img } from "./PartnersHeroElemets";
import {
  LeftRightContainer,
  TextColumn,
  BoldSpan,
  H1,
  ImageColumn,
  SubTitle,
} from "../../SharedComponents/SharedComponents";

const PrizyPartnersHero = () => {
  const { t } = useTranslation();

  return (
    <>
      <LeftRightContainer
        color="linear-gradient(45deg,#EAA1A1, #EAA1D2);"
        imgStart={0}
      >
        <TextColumn className="animate">
          <H1 center={0}>
            <BoldSpan>{t("partners-title")}</BoldSpan>
          </H1>
          <SubTitle center={0}>{t("partners-subtitle")}</SubTitle>
        </TextColumn>
        <ImageColumn className="animate">
          <Img src={img} alt="Liste des partenaires de Prizy"></Img>
        </ImageColumn>
      </LeftRightContainer>
    </>
  );
};

export default PrizyPartnersHero;
