import React from 'react'
import { Container } from '../SharedComponents/SharedComponents'
import AbstractsComponent from './AbstractsComponent'
import BlogHero from './BlogHero'

const Blog = () => {
  return (
    <>
      <BlogHero></BlogHero>
      <Container>
        <AbstractsComponent></AbstractsComponent>
      </Container>
      
    </>
    
  )
}

export default Blog;
