import motivez from '../../assets/motivez.png'
import incentive from '../../assets/incentive.png'
import article3 from '../../assets/article3.png'
import article4 from '../../assets/article4.png'
import article5 from '../../assets/article5.png'
import article6 from '../../assets/article6.png'
import article7 from '../../assets/article7.png'


export const blogData=[
    {
        path:"/Votre-allié-pour-des-avantages-salariés-exceptionnels",
        title:"Prizy : Votre allié pour des avantages salariés exceptionnels",
        abstract:"Prizy, la solution ultime pour offrir des avantages salariés exceptionnels à vos employés ! Chez Prizy,...",
        date:"13 Juillet 2023",
        time:"04",
        image:article7,
    },
    {
        path:"/Limportance-des-activités-entre-collaborateurs",
        title:"L'importance des activités entre collaborateurs ",
        abstract:"L'esprit d'équipe et la collaboration sont essentiels pour favoriser une dynamique de...",
        date:"30 Avril 2023",
        time:"03",
        image:article6,
    },
    {
        path:"/Les-5-choses-à-éviter-lors-d’un-challenge-commercial",
        title:"Les 5 choses à éviter lors d’un challenge commercial",
        abstract:"Un challenge commercial est un outil de motivation et de stimulation des équipes de vente...",
        date:"23 Mars 2023",
        time:"10",
        image:article5,
    },
    {
        path:"/Comment-améliorer-la-performance-de-ses-employés",
        title:"Comment améliorer la performance de ses employés ?",
        abstract:"La performance des employés est un facteur clé de la réussite d'une entreprise. Elle dépend...",
        date:"23 Mars 2023",
        time:"05",
        image:article4,
    },
    {
        path:"/Organiser-un-concours-pour-les-commerciaux",
        title:"Organiser un concours pour les commerciaux",
        abstract:"Pour mettre en place un concours pour commerciaux, il faut suivre les étapes suivantes :...",
        date:"23 Mars 2023",
        time:"05",
        image:article3,
    }, 
    {
        path:"/Incentive",
        title:"Incentive",
        abstract:"Un programme incentive est l'utilisation structurée de récompenses et de reconnaissance pour motiver...",
        date:"14 Décembre 2022",
        time:"10",
        image:incentive,
    },
    {
        path:"/Motivez&Fidélisez-vos-collaborateurs",
        title:"Motivez & Fidélisez vos collaborateurs",
        abstract:"La motivation et la fidélisation de vos équipes sont des enjeux managériaux et RH de premier plan...",
        date:"14 Décembre 2022",
        time:"05",
        image:motivez,
    },
   
    
    
   
    
]

export const blogPartialData=[
    {
        path:"/Votre-allié-pour-des-avantages-salariés-exceptionnels",
        title:"Prizy : Votre allié pour des avantages salariés exceptionnels",
        abstract:"Prizy, la solution ultime pour offrir des avantages salariés exceptionnels à vos employés ! Chez Prizy,...",
        date:"13 Juillet 2023",
        time:"04",
        image:article7,
    },
    {
        path:"/Limportance-des-activités-entre-collaborateurs",
        title:"L'importance des activités entre collaborateurs ",
        abstract:"L'esprit d'équipe et la collaboration sont essentiels pour favoriser une dynamique de...",
        date:"30 Avril 2023",
        time:"03",
        image:article6,
    },
     
]