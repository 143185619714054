import React from 'react'
import { avantagesPrizy } from './AvantagesData'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useTranslation } from "react-i18next";
import {AvantagesContainer,Img,AvantageTitle,Span,BoldSpan,AvantageH1} from './AvantagesElements'
import { IconContainer,H1,LeftRightContainer,SubTitle,AdvantageWrapper,AdvantageContent,AvantageText,TextColumn,ImageColumn} from '../../SharedComponents/SharedComponents'



const AvantagesSection = () => {

  const { t } = useTranslation();
  return (
    <>
    <AvantagesContainer>
        <AnimationOnScroll animateIn="animate__fadeIn" delay={2} animateOnce={1}>

            <H1 center={1} style={{ paddingLeft : "5%", paddingRight : "5%" }}>{t('landingpage-avantages-title')}</H1>
            <SubTitle center={1} style={{ paddingLeft : "5%", paddingRight : "5%" }}>{t('landingpage-avantages-subtitle')}</SubTitle>

        </AnimationOnScroll>
        
        {avantagesPrizy.map((item, index1) => {
             
              
              return (
                <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
                    <LeftRightContainer color={item.color} imgStart={item.imageStart}>
                                <TextColumn>
                                    <AvantageH1 pink={!item.pink} left={1}>
                                            <Span>{t(item.title[0])}</Span>
                                            <BoldSpan>{t(item.title[1])}</BoldSpan>
                                    </AvantageH1>
                                    {item.avantages.map((avantage, index2) => {
                                            return (
                                                <AdvantageContent pink={item.pink}>
                                                    <IconContainer color={avantage.color}>
                                                        {avantage.icon}
                                                    </IconContainer>
                                                    <AdvantageWrapper>
                                                        <AvantageTitle>{t(avantage.title)}</AvantageTitle>
                                                        <AvantageText>{t(avantage.subTitle)}</AvantageText>
                                                    </AdvantageWrapper>
                                                    
                                                </AdvantageContent>
                                            );
                                    })}

                                </TextColumn>
                                <ImageColumn>
                                    <Img src={item.imagelink} alt={t(item.title[0])+" avec Prizy"}></Img>
                                </ImageColumn>
                    </LeftRightContainer>
                </AnimationOnScroll>
                  
              );
        })}
        
    </AvantagesContainer>
    
        
      
    </>
  )
}

export default AvantagesSection
