import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import {
  LeftRightContainer,
  ImageColumn,
  TextColumn,
  Button,
  BtnWrapper,
  H1,
  SubTitle,
  ArrowForward,
  ArrowRight,
} from "../../SharedComponents/SharedComponents";
import { Img } from "./OffersElements";
import img from "../../../assets/offers.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";

const OffersSection = () => {
  const gaEventTracker = useAnalyticsEventTracker(
    "Offers Section From Landing Page"
  );
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
    gaEventTracker("Go to offers from landing page");
    ReactGA.event({
      category: "Offers Section From Landing Page",
      action: "Go to offers",
    });
  };
  const { t } = useTranslation();
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
      <LeftRightContainer color={"#0a0a0a"} imgStart={1}>
        <TextColumn>
          <H1 white={1} center={0}>
            {t("landingpage-offers-title")}
          </H1>
          <SubTitle white={1} center={0}>
            {t("landingpage-offers-subtitle1")}
          </SubTitle>
          <SubTitle white={1} center={0}>
            {t("landingpage-offers-subtitle2")}
          </SubTitle>
          <BtnWrapper>
            <Button
              color={"#e9a1d1"}
              dark={0}
              to="/offers"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t("voiroffres")}
              {hover ? <ArrowRight white={0} /> : <ArrowForward white={0} />}
            </Button>
          </BtnWrapper>
        </TextColumn>
        <ImageColumn>
          <Img src={img} alt="Offres de Prizy"></Img>
        </ImageColumn>
      </LeftRightContainer>
    </AnimationOnScroll>
  );
};

export default OffersSection;
