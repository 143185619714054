import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ReactGA from "react-ga4";
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en','ar','fr'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    scroll: false
  })


const root = ReactDOM.createRoot(document.getElementById("root"));
const TRACKING_ID = "G-HVC54339LW"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


root.render(

  <React.StrictMode>
    <App />
  </React.StrictMode>
 
);
