import styled from "styled-components";
import {Link} from 'react-router-dom'


export const CopyrightsContainer = styled.div`
    margin-top: auto;
    padding: 1rem;
    background-color: #0a0a0a;
    width: 100%;
    display:flex;
    justify-content:center;
`;
export const Copyrights = styled.p`
    font-size:1rem;
    font-weight:500;
    color:#fff;
`;

export const FooterLogo=styled.img`
    justify-self:center;
    cursor:pointer;
    display:flex;
    align-items:center;
    height:3rem;
    object-fit:contain;
    align-self:start;
`;

export const Box = styled.div`
  padding: 5% 5%;
  background: black;
  margin:auto;
  width: 100%;

  background: #211f1f;
  @media screen and (max-width:960px){
    padding:10% 5%;
} 
`;
   
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
   
    margin: 0 auto;
    /* background: red; */
`
   
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 30px;
`;
   
export const Row = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 
                         minmax(200px, 1fr));
  grid-gap: 1.2rem;
   
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;
   
export const FooterLink = styled(Link)`
  color: #fff;
  margin-bottom: 20px;
  font-size: 1rem;
  text-decoration: none;
   
  &:hover {
      color: pink;
      transition: 200ms ease-in;
  }
`;

export const FooterP = styled.p`
  color: #fff;
  margin-top: 20px;
  font-size: 1rem;
 
`;
   
export const Heading = styled.p`
  font-size: 1.1rem;
  color: #fff;
  margin-bottom: 1rem;
  font-weight: bold;
`;

export const ContactContent =styled.div`
 
    height:fit-content;
    width:100%;
    display: flex;
    justify-content: start;
    align-items:start;
    gap:1rem;

`;

