import "./App.css";

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages";
import SolutionPage from "./pages/solution";
import Offers from "./pages/offers";
import PrizyPro from "./pages/prizypro";
import Partners from "./pages/partners";
import Contact from "./pages/contact";
import Sidebar from "./components/NavComponents/Sidebar";
import Navbar from "./components/NavComponents/Navbar";
import FooterSection from "./components/NavComponents/FooterSection";
import BlogPage from "./pages/blog";
import Article2 from "./components/Blog/Article2";
import Article1 from "./components/Blog/Article1";
import ScrollToTop from "./components/ScrollToTop";
import Preloader from "./components/Preloader/Preloader";
import Article3 from "./components/Blog/Article3";
import Article4 from "./components/Blog/Article4";
import Article5 from "./components/Blog/Article5";
import Article6 from "./components/Blog/Article6";
import Article7 from "./components/Blog/Article7";
import ReactGA from "react-ga4";
import MobileApp from "./pages/mobileapp";

const TRACKING_ID = "G-HVC54339LW"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.href,
    });
  }, []);

  return (
    <Router className="App">
      <Sidebar isOpen={isOpen} toggle={toggle}></Sidebar>
      <Navbar toggle={toggle}></Navbar>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home></Home>} exact></Route>
          <Route
            path="/solution"
            element={<SolutionPage></SolutionPage>}
            exact
          ></Route>
          <Route path="/offers" element={<Offers></Offers>} exact></Route>
          <Route path="/mobileapp" element={<MobileApp></MobileApp>} exact></Route>
          <Route path="/blog" element={<BlogPage></BlogPage>} exact></Route>
          <Route path="/prizypro" element={<PrizyPro></PrizyPro>} exact></Route>
          <Route path="/partners" element={<Partners></Partners>} exact></Route>
          <Route
            path="/aideetcontact"
            element={<Contact></Contact>}
            exact
          ></Route>
          <Route
            path="/Motivez&Fidélisez-vos-collaborateurs"
            element={<Article1></Article1>}
            exact
          ></Route>
          <Route
            path="/Incentive"
            element={<Article2></Article2>}
            exact
          ></Route>
          <Route
            path="/Organiser-un-concours-pour-les-commerciaux"
            element={<Article3></Article3>}
            exact
          ></Route>
          <Route
            path="/Comment-améliorer-la-performance-de-ses-employés"
            element={<Article4></Article4>}
            exact
          ></Route>
          <Route
            path="/Les-5-choses-à-éviter-lors-d’un-challenge-commercial"
            element={<Article5></Article5>}
            exact
          ></Route>
          <Route
            path="/Limportance-des-activités-entre-collaborateurs"
            element={<Article6></Article6>}
            exact
          ></Route>
          <Route
            path="/Votre-allié-pour-des-avantages-salariés-exceptionnels"
            element={<Article7></Article7>}
            exact
          ></Route>
        </Routes>
      </ScrollToTop>
      <Preloader></Preloader>
      <FooterSection></FooterSection>
    </Router>
  );
}

export default App;
