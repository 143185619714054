import React from 'react'
import { useTranslation } from "react-i18next";
import {Container,SubTitle,H1 } from '../../SharedComponents/SharedComponents'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import LandingPageAbstractComponents from '../../Blog/LandingPageAbstractComponents';



const BlogSection = () => {
  const { t } = useTranslation();
  return (
    <AnimationOnScroll animateIn="animate__fadeIn" delay={2} animateOnce={1}>
      <Container>    
        <H1 center={1}>{t('blog-title')}</H1>   
        <SubTitle center={1}>{t('blog-subtitle')}</SubTitle>
        <LandingPageAbstractComponents></LandingPageAbstractComponents>
      </Container>
    </AnimationOnScroll>

  
    
  )
}

export default BlogSection
