import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BoldSpan,
  H1,
  Span,
  SubTitle,
} from "../../SharedComponents/SharedComponents";

import {
  NewsContainer,
  NewsLetterForm,
  NewsLetterInput,
  NewsLetterSubmit,
  ValidationWrapper,
  ErrorWrapper,
  Message,
} from "./NewsElements";
import axios from "axios";
import ReactGA from "react-ga4";

const API_PATH = "http://localhost/newsletter.php";
const NewsLetter = () => {
  const { t } = useTranslation();
  const [enteredEmail, setEnteredEmail] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
    setError(false);
    setMailSent(false);
    console.log(event.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    const { email } = e.target.elements;

    if (!isValidEmail(email.value)) {
      setError(true);
      ReactGA.event({
        category: "Newsletter",
        action: "Newsletter subscription",
      });
    } else {
      let conFom = {
        email: email.value,
      };
      console.log(conFom);

      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: conFom,
      })
        .then((result) => {
          if (result.data.sent) {
            setMailSent(result.data.sent);
            setError(false);
          } else {
            setError(true);
          }
        })
        .catch((error) => setError(error.message));

      setEnteredEmail("");

      ReactGA.event({
        category: "Newsletter",
        action: "Newsletter subscription",
      });
    }
  };
  return (
    <NewsContainer>
      <H1 center={1}>
        <Span>{t("recevez")}</Span>
        <BoldSpan>{t("newsletter")}</BoldSpan>
      </H1>
      <SubTitle center={1}>{t("newsletter-subtitle")}</SubTitle>

      <NewsLetterForm onSubmit={onSubmit}>
        <NewsLetterInput
          type="email"
          value={enteredEmail}
          onChange={emailChangeHandler}
          id="email"
          required
        ></NewsLetterInput>
        <NewsLetterSubmit type="submit">{t("envoyer")}</NewsLetterSubmit>
      </NewsLetterForm>

      {mailSent && (
        <ValidationWrapper style={{ visibility: mailSent }}>
          <Message>
          {t("positifmessage")}
          </Message>
        </ValidationWrapper>
      )}

      {error && (
        <ErrorWrapper style={{ visibility: mailSent }}>
          <Message>{t("negatifmessage")}</Message>
        </ErrorWrapper>
      )}
    </NewsContainer>
  );
};

export default NewsLetter;
