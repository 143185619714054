import React from 'react'
import { useTranslation } from "react-i18next";
import img from '../../../assets/solution.png'
import {Img} from '../SolutionElements'

import {LeftRightContainer,TextColumn,ImageColumn,H1,BoldSpan, Span,SubTitle} from '../../SharedComponents/SharedComponents'

const SolutionHero = () => {
  const { t } = useTranslation();
  return (
    <>
        <LeftRightContainer color='#e9f7f5;' imgStart={0}>
                    <TextColumn className='animate'>
                        
                        <H1 center={0}>
                            <Span>{t('enchantez')} {t('vos')} </Span>
                            <BoldSpan>{t('collaborateurs')} </BoldSpan>
                            <Span>, {t('vos')} </Span>
                            <BoldSpan>{t('partners')} </BoldSpan>
                            <Span>{t('et')} {t('vos')} </Span>
                            <BoldSpan>{t('clients')} </BoldSpan>
                            <Span>{t('withprizy')}</Span>
                        </H1>
                        <SubTitle center={0}>{t('solutionpage-subtitle')}</SubTitle>
                    </TextColumn>
                    <ImageColumn className='animate'>
                        <Img src={img} alt='À propos de Prizy en tant que solution'></Img>
                    </ImageColumn>
        </LeftRightContainer>
      
    </>
  )
}

export default SolutionHero
