import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Data } from './Data';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import {AccordionSection,Container,Wrap,Dropdown,Span,BoldSpan} from './FAQElements'
import ReactGA from "react-ga4";


const FAQ = () => {

    const [clicked, setClicked] = useState(false);
    const { t } = useTranslation();

  
    const toggle = (index,item ) => {
      if (clicked === index) {
        //if clicked question is already active, then close it
        return setClicked(null);
      }
     
      ReactGA.event({
        category: item,
        action: 'FAQ Section',
       
      });
      setClicked(index);
    };
  
    return (
      <IconContext.Provider value={{ color: '#0a0a0a', size: '16px' }}>
        <AccordionSection>
          <Container>
            {Data.map((item, index) => {
              return (
                <>
                  <Wrap onClick={() => toggle(index,item.question)} key={index}>
                    <Span>{t(item.question)}</Span>
                    <span>{clicked === index ? <FiMinus  color='#e9a1ac'/> : <FiPlus  color='#e9a1ac' />}</span>
                  </Wrap>
                  {clicked === index ? (
                    <Dropdown>
                      <BoldSpan>{t(item.answer)}</BoldSpan>
                    </Dropdown>
                  ) : null}
                </>
              );
            })}
          </Container>
        </AccordionSection>
      </IconContext.Provider>
    );
  };

export default FAQ
