export const Data =[
    {
     question:"question1",
     answer: "answer1"
    },
    {
     question: "question2",
     answer: "answer2"
    },
    {
     question: "question3",
     answer: "answer3"
    },
    {
     question: "question4",
     answer: "answer4"
    },
    {
     question: "question5",
     answer: "answer5"
    },
    {
     question:'question6',
     answer:"answer6",
    }

   ];