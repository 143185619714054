import React from 'react'
import {ArticleParagraph,TimerWrapper,Timericon, SliderCardwrapper} from './BlogElements'
import {TextColumn,SectionH1,BoldSpan} from '../SharedComponents/SharedComponents'

const ArticleBanner = (props) => {
    
    return (
    <SliderCardwrapper img ={props.img}>
            <TextColumn>
                <SectionH1 center={0}>
                    <BoldSpan>{props.title}</BoldSpan>
                </SectionH1>
                <ArticleParagraph white={1}>Date : {props.date}</ArticleParagraph>  
                <TimerWrapper>
                    <Timericon/>
                    <ArticleParagraph white={1}>{props.time} minutes de lecture</ArticleParagraph>
                </TimerWrapper>       
            </TextColumn>
    </SliderCardwrapper>
  )
}

export default ArticleBanner