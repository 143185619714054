import styled from "styled-components";


export const ReviewCardContainer=styled.div`
    
    background:#fff;
    color:#0a0a0a;

    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;

    border-radius:5px;
    border:2px solid #f9f9f9;
    box-shadow: 0px 0px 10px 1px rgba(161, 208, 185, 0.3);

    height:fit-content;
    min-height:550px;
    max-width: 400px;

    padding:2rem;
    margin:1rem 0rem;

    @media screen and (max-width:960px){
        min-height:0;
    }

    &:hover{
        border:1px solid #a0cec6;
    }
`;
export const ReviewImg=styled.img`
    height:80px;
    border-radius: 50%;
    margin-bottom:1.5rem;

`;
export const CompanyLogo=styled.img`
    height:100px;
    margin-bottom:1.5rem;
    color:#EAA1BA;

`;
export const LogoWrapper=styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
    width:100%;

`;


export const Name=styled.h2`
    font-size:1.2rem;
    font-weight:700;
    @media screen and (max-width:480px){
        font-size:1rem;
        margin-bottom:1rem;
    }

`;

export const Role=styled.p`
    font-size:1rem;
    font-weight:500;
    margin-bottom:1rem;
    @media screen and (max-width:480px){
        font-size:0.8rem;
        margin-bottom:1rem;
    }

`;

export const Review=styled.p`
    font-size:1rem;
    font-weight:400;
    margin-bottom:1rem;
    @media screen and (max-width:480px){
        font-size:0.8rem;
        margin-bottom:1rem;
    }

`;

export const ReviewsWrapper=styled.div`
    height:fit-content;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`;



