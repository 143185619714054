import React from 'react'
import { useTranslation } from "react-i18next";
import {Container,H1} from '../../SharedComponents/SharedComponents'
import {StepsWrapper,StepCard1,StepCard2,StepCard3,StepCard4,StepCard5,StepImage,StepNumber,Steptext} from './HowDoesItWorkElements'

import { AnimationOnScroll } from 'react-animation-on-scroll'

import step1 from '../../../assets/step1.png'
import step2 from '../../../assets/step2.png'
import step3 from '../../../assets/step3.png'
import step4 from '../../../assets/step4.png'
import step5 from '../../../assets/step5.png'


const HowDoesItWork = () => {
  const { t } = useTranslation();
 
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
      <Container>
        <H1 center={1}>{t('simpleutilisation')}</H1>
        <StepsWrapper>
         
                <StepCard1 className='delay-1' pink={0}>
                    <StepNumber>01.</StepNumber>
                    <StepImage src={step1} alt={"Comment fonctionnent les cartes-cadeaux Prizy (étape 01) : "+t('step1')}></StepImage>
                    <Steptext>{t('step1')}</Steptext>
                </StepCard1>
           
                <StepCard2 className='delay-2' pink={1}>
                    <StepNumber>02.</StepNumber>
                    <StepImage src={step2} alt={"Comment fonctionnent les cartes-cadeaux Prizy (étape 02) : "+t('step2')}></StepImage>
                    <Steptext>{t('step2')}</Steptext>
                </StepCard2>
         
                <StepCard3 className='delay-3'  pink={0}>
                    <StepNumber>03.</StepNumber>
                    <StepImage src={step3} alt={"Comment fonctionnent les cartes-cadeaux Prizy (étape 03) : "+t('step3')}></StepImage>
                    <Steptext>{t('step3')}</Steptext>
                </StepCard3>

                <StepCard4 className='delay-3'  pink={0}>
                    <StepNumber>04.</StepNumber>
                    <StepImage src={step4} alt={"Comment fonctionnent les cartes-cadeaux Prizy (étape 04) : "+t('step4')}></StepImage>
                    <Steptext>{t('step4')}</Steptext>
                </StepCard4>
       
                <StepCard5 className='delay-4' pink={1}>
                    <StepNumber>05.</StepNumber>
                    <StepImage src={step5} alt={"Comment fonctionnent les cartes-cadeaux Prizy (étape 05) : "+t('step5')}></StepImage>
                    <Steptext>{t('step5')}</Steptext>
                    
                </StepCard5>
        </StepsWrapper>


    </Container>
                
    </AnimationOnScroll>
  )
}


export default HowDoesItWork;