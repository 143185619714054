import styled from "styled-components";


export const NumberWrapper=styled.div`
    
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items:start;
    justify-content:center;
    grid-gap:2rem;
    width:100%;

    @media screen and (max-width:960px){
        grid-template-columns: 1fr 1fr;
    }
`;


export const NumberCard=styled.div`
    background:transparent;
    color:#0a0a0a;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:1rem;
`;



export const NumberSpan=styled.span`
    font-size:5rem;
    font-weight:400;
    color:${props=>props.color};

    @media screen and (max-width:960px){
        font-size:4rem;
    }
    @media screen and (max-width:480px){
        font-size:3rem;
    }

`;


export const Indicateur=styled.p`
    font-size:1.3rem;
    font-weight:400;
    text-align:center;
    @media screen and (max-width:960px){
        font-size:1rem;
    }
    
`;



