import styled from 'styled-components'


export const Img=styled.div`
width:500px;
height:500px;
align-self: center;   
background:url(${props=>props.img});
background-size: contain;
background-repeat: no-repeat;
transition: background 0.4s ease;

`;






