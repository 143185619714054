import React from "react";
import { useTranslation } from "react-i18next";
import { Img } from "../AdvantageElements";

import {
  LeftRightContainer,
  AvantageH1,
  TextColumn,
  ImageColumn,
  BoldSpan,
  Span,
  AvantageText,
  IconContainer,
  AdvantageWrapper,
  AdvantageContent,
} from "../../SharedComponents/SharedComponents";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { avantagesPrizyPro } from "../AvantageData";

const AvantageOneTwo = () => {
  const { t } = useTranslation();
  return (
    <>
      {avantagesPrizyPro.map((item, index1) => {
        return (
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            delay={2}
            animateOnce={1}
          >
            <LeftRightContainer color={item.color} imgStart={item.imageStart}>
              <TextColumn>
                <AvantageH1 center={0}>
                  <Span>{t(item.title[0])}</Span>
                  <BoldSpan>{t(item.title[1])}</BoldSpan>
                </AvantageH1>

                <AdvantageWrapper>
                  {item.avantages.map((avantage, index2) => {
                    return (
                      <AdvantageContent>
                        <IconContainer color={avantage.color}>
                          {avantage.icon}
                        </IconContainer>
                        <AvantageText>{t(avantage.sousTitre)}</AvantageText>
                      </AdvantageContent>
                    );
                  })}
                </AdvantageWrapper>
              </TextColumn>
              <ImageColumn>
                <Img
                  src={item.imagelink}
                  alt={t(item.title[0])+" avec Prizy Pro by Prizy"}
                ></Img>
              </ImageColumn>
            </LeftRightContainer>
          </AnimationOnScroll>
        );
      })}
    </>
  );
};

export default AvantageOneTwo;
