import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import img from "../../../assets/prizypro.svg";
import { Img } from "./PrizyProHeroElements";
import {
  LeftRightContainer,
  BoldSpan,
  Span,
  TextColumn,
  ImageColumn,
  H1,
  BtnWrapper,
  Button,
  SubTitle,
  ArrowForward,
  ArrowRight,
} from "../../SharedComponents/SharedComponents";

const PrizyProHero = () => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    ReactGA.event({
      category: "Prizy Pro",
      action: "Go to contact",
    });
    setHover(!hover);
  };
  return (
    <>
      <LeftRightContainer color={"#fff2e2;"} imgStart={0}>
        <TextColumn className="animate">
          <H1 center={0}>
            <Span>{t("generez")} </Span>
            <BoldSpan>
              {t("vos")} {t("propresgiftcards")}{" "}
            </BoldSpan>
            <Span>{t("with")} </Span>
            <BoldSpan>Prizy Pro </BoldSpan>
          </H1>
          <SubTitle center={0}>
            Prizy Pro : {t("prizypropage-hero-subtitle")}{" "}
          </SubTitle>
          <BtnWrapper>
            <Button
              to="/aideetcontact"
              color={"#0a0a0a"}
              dark={1}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t("join")}{" "}
              {hover ? <ArrowRight white={1} /> : <ArrowForward white={1} />}
            </Button>
          </BtnWrapper>
        </TextColumn>
        <ImageColumn className="animate">
          <Img src={img} alt="L'offre Prizy Pro de Prizy"></Img>
        </ImageColumn>
      </LeftRightContainer>
    </>
  );
};

export default PrizyProHero;
