import styled from "styled-components";
import { hoverCardStyle } from "../../SharedComponents/SharedComponents";

export const PartnersWrapper=styled.ul`
    
    padding:2rem 0;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows:masonry;
    grid-gap:2rem;

    @media screen and (max-width:960px){
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width:480px){
        grid-template-columns: 1fr;
        grid-gap:0.5rem;
    }

`;


export const CardWrapper=styled.div`
    
    color:#0a0a0a;
    border-radius:8px;


    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:start;
    
    height:fit-content;
    min-height:350px;
    max-width:300px;
    min-width:250px;

    

    @media screen and (max-width:960px){
        min-width:200px;
    }
    
    
    ${hoverCardStyle};

`;



export const TitleWrapper=styled.div`
    padding-top: 0.75rem;
    width:100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-start;
    gap: 0.5rem;
`;
export const CaracteristicWrapper=styled.div`
    box-sizing: border-box;
    display: ${({display})=>(display?"flex":"none")};
    flex-direction: row;
    justify-content: center;
    align-items:center;
`;
export const CaracteristicsWrapper=styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
`;
export const Dot=styled.div`
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 9999px;
    background-color: ${props => props.color};
    margin-inline-end: 0.5rem;
`;
export const Caracteristic=styled.p`
    font-size: 12px;
    font-weight:500;
    color:${props => props.color};
`;

export const CardLogo=styled.div`
    background-image: url('${props => props.bg}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 10px gray;

    width:65px;
    height:65px;
    border-radius:500px;
    background-color: white;
    position: absolute;
    top: 5px;
    left: 5px;
   
`;




export const CardImg=styled.div`
    background-image: url('${props => props.bg}');
    background-size: cover;
    position: relative;

    border-radius: 5px ;
    width: 100%;
    height: 200px;
    margin-bottom:1rem;


    &::before{
        background-color: rgba(0, 0, 0, 0.37);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        border-radius: 5px ;
      }
`;
export const CardTitle=styled.p`
    font-size:1.5rem;
    font-weight:600;
    margin-bottom:0.5rem;

`;
export const CardSubTitle=styled.p`
    font-size:0.9rem;
    font-weight:400;
    margin-bottom:1rem;
    color:#54545B;
    

`;

export const InlineFilters=styled.div`
    padding:1rem ;
    z-index :5;
    border-radius:8px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items:space-between;
    grid-gap:1rem;
    width:100%;
    margin-top: -142px;

    @media screen and (max-width:960px){
        grid-template-columns: 1fr 1fr;
        margin:0;
        border: 1px solid #FAE8EB;
    }

    @media screen and (max-width:480px){
        grid-template-columns: 1fr;
        margin:0;
        border: 2px solid #FAE8EB;
    }
   
`;

export const FilterWrapper=styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:start;  
`;
export const FilterLabel=styled.label`
    font-weight:500;
    margin-bottom:0.5rem;
    visibility: hidden;
    @media screen and (max-width:960px){
        visibility: visible;
    }

   
`;

export const ResearchInput=styled.input`
    padding: 1rem;
    background:#fff;
    border: 2px solid #96eadc;
    border-radius: 50px;
    width: 100%;
    color:#0a0a0a;
    font-weight:500;
    &:focus {
        outline: none !important;
        border: 2px solid #0a0a0a;
      
    }
   
`;

export const Select=styled.select`
    width: 100%;
    padding: 1rem;
    background:#fff;
    border: 2px solid #96eadc;
    color:#0a0a0a;
    font-weight:500;
    border-radius: 50px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
        linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        radial-gradient(#96eadc 70%, transparent 72%);
    background-position:
        calc(100% - 25px) calc(1em + 7px),
        calc(100% - 20px) calc(1em + 7px),
        calc(100% - .7em) .6em;
    background-size:
        5px 5px,
        5px 5px,
        2em 2em;
    background-repeat: no-repeat;

    &:focus {
        outline: none !important;
        border: 2px solid #0a0a0a;
      
    }
    
`;

export const Option=styled.option`
    padding:1rem;
    
   
   
`;

export const CategoriesSelect=styled.ul`
    width:100%;
`;

export const CatgoriesOption= styled.li`
    display:inline-block;
    margin-right: 1rem;
    cursor:pointer;
    font-weight:${({active})=>(active?"600":"400")};
    ${({active})=>(active?"border-bottom: 3px solid #96eadc;":"")};
    padding: 1rem 0;
   
`;

export const CategoriesWrapper=styled.div`
    width:100%;
    overflow-x: auto;
    white-space: wrap;
    padding:0rem 1rem;
   
`;
export const EmtyStateContainer=styled.div`
    width:100%;
    padding:5%;
    margin: 2rem 0;
    border-radius:10px;
    border: 1px solid grey;
    background:#f9f9f9;
    display:flex;
    justify-content:center;
    align-items:center;
   
`;


