import styled,{keyframes}from "styled-components";
import { IoCard,IoNotifications } from "react-icons/io5";
import { MdOutlineAccountBalanceWallet,MdOutlineStorefront } from "react-icons/md";
import { FaMoneyCheck,FaShareAlt } from "react-icons/fa";


import { commonIconStyle } from "../../SharedComponents/SharedComponents";



export const SuperWalletIcon=styled(IoCard)`
    ${commonIconStyle}
`;
export const BallanceIcon=styled(MdOutlineAccountBalanceWallet)`
    ${commonIconStyle}
`;
export const TransactionsIcon=styled(FaMoneyCheck)`
    ${commonIconStyle}
`;
export const ShareIcon=styled(FaShareAlt)`
    ${commonIconStyle}
`;
export const StoresIcon=styled(MdOutlineStorefront)`
    ${commonIconStyle}
`;
export const NotificationIcon=styled(IoNotifications)`
    ${commonIconStyle}
`;



export const AvantagesContainer=styled.div`
    height:fit-content;
    padding: 0%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
   
`;


const rainbow_text_simple_animation_rev=keyframes`
      0% {
          background-size: 650%;
      }
      40% {
          background-size: 650%;
      }
      100% {
          background-size: 100%;
      }
  `;

const rainbow_text_simple_animation =keyframes`
      0% {
          background-size: 100%;
      }
      80% {
          background-size: 650%;
      }
      100% {
          background-size: 650%;
      }
`;


export const BoldSpan=styled.span`
    font-weight:900;
    font-family: "Kurye"; 
    font-size:2rem;

    background-color: #EAA1C6;
    
 
     background-image: linear-gradient(
          45deg,
          #EAA1A1 16.666%, 
          #EAA1AD 16.666%, 
          #EAA1BA 33.333%, 
          #EAA1C6 33.333%, 
          #EAA1D2 50%, 
          #A1CEB0 50%, 
          #A1D0B9 66.666%, 
          #A1CEBF 66.666%, 
          #A1CEC6 83.333%, 
          #A1CEBF 83.333%);
    
    background-size: 100%;
    background-repeat: repeat;
  
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 

    animation: ${rainbow_text_simple_animation_rev} 0.75s ease forwards;

    &:hover{
        animation: ${rainbow_text_simple_animation} 0.5s ease-in forwards;
    }
    @media screen and (max-width:480px){
        font-size:1.5rem;
    }
  
`;

 

export const Span=styled.span`
    font-weight:500;
    font-size:2rem;
    @media screen and (max-width:480px){
        font-size:1.5rem;
    }
  
`;


export const AvantageTitle=styled.p`
    font-size:1.5rem;
    font-weight: 700;
    
    @media screen and (max-width:480px){
    font-size:1.3rem;
    }
`;


export const AvantageH1=styled.h1`
    color:#0a0a0a;
    margin-bottom:2rem;
`;


export const Img=styled.img`
    width:80%;
    height:100%;
  
`;
