export const MenuItems = [
  {
    title: 'cards',
    path: '/offers',
    cName: 'dropdown-link'
  },
  {
    title: 'prizypro',
    path: '/prizyPro',
    cName: 'dropdown-link'
  },
 
];
