import partenaires from "../../../assets/partenaires.png"
import clients from "../../../assets/beneficiaire.png"
import decideurs from "../../../assets/decideurs.png"
import { DigitalIcon, TruckIcon, ChartIcon, SupportIcon, ShoppingIcon, MoneyIcon, ChiffreAffaireIcon, EyeIcon, HandShakeIcon,MapIcon} from './AvantagesElements'


export const avantagesPrizy=[
    {
        imageStart:1,
        pink:0,
        color:'#fff',
        title:["pourles","avantagedecideurs"],
        imagelink:decideurs,
        avantages:[
            {
                icon:<DigitalIcon></DigitalIcon>, 
                color:"#e7d6fd",
                title: "avantagedecideurs-title1",
                subTitle:"avantagedecideurs-subtitle1",
            },
            {
                icon:<TruckIcon></TruckIcon>,
                color:"#ffd9ed",
                title: "avantagedecideurs-title2",
                subTitle:"avantagedecideurs-subtitle2",
            },
            {
                icon:<ChartIcon></ChartIcon>,
                color:"#c9faf8",
                title: "avantagedecideurs-title3",
                subTitle:"avantagedecideurs-subtitle3",
            },
            {
                icon:<SupportIcon></SupportIcon>,
                color:"#dddeff",
                title: "avantagedecideurs-title4",
                subTitle:"avantagedecideurs-subtitle4",
            }
        ]

        
    },
    {
        imageStart:0,
        pink:1,
        color:'#e8f5f9',
        title:["pourles","avantagebeneficiaires"],
        imagelink:clients,
        avantages:[
            {
                icon:<ShoppingIcon></ShoppingIcon>,
                color:"#cdfae3",
                title: "avantagebeneficiaires-title1",
                subTitle:"avantagebeneficiaires-subtitle1",
            },
            {
                icon:<MapIcon></MapIcon>,
                color:"#fdf6bb",
                title: "avantagebeneficiaires-title2",
                subTitle:"avantagebeneficiaires-subtitle2",
            },
            {
                icon:<MoneyIcon></MoneyIcon>,
                color:"#f9d3ff",
                title: "avantagebeneficiaires-title3",
                subTitle:"avantagebeneficiaires-subtitle3",
            },
            {
                icon:<SupportIcon></SupportIcon>,
                color:"#ffdede",
                title: "avantagebeneficiaires-title4",
                subTitle:"avantagebeneficiaires-subtitle4",
            }
        ]

        
    },
    {
        imageStart:1,
        pink:0,
        color:'#fff',
        title:["pourles","avantagepartenaires"],
        imagelink:partenaires,
        avantages:[
            {
                icon:<ChiffreAffaireIcon></ChiffreAffaireIcon>,
                color:"#ffe2b2",
                title: "avantagepartenaires-title1",
                subTitle:"avantagepartenaires-subtitle1",
            },
            {
                icon:<EyeIcon></EyeIcon>,
                color:"#e4f8b1",
                title: "avantagepartenaires-title2",
                subTitle:"avantagepartenaires-subtitle2",
            },
            {
                icon:<HandShakeIcon></HandShakeIcon>,
                color:"#dddeff",
                title: "avantagepartenaires-title3",
                subTitle:"avantagepartenaires-subtitle3",
            },
        ]
   
    },
]