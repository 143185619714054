import styled,{keyframes}from "styled-components";
import {MdOutlinePhonelink,MdWaterfallChart} from "react-icons/md";
import {TbTruckOff} from "react-icons/tb"; 
import {FiPieChart,FiEye} from "react-icons/fi";
import {BiSupport} from "react-icons/bi"
import {BiShoppingBag, BiMapAlt} from "react-icons/bi";
import {GrMoney} from "react-icons/gr";
import {FaRegHandshake} from "react-icons/fa";
import { commonIconStyle } from "../../SharedComponents/SharedComponents";



export const DigitalIcon=styled(MdOutlinePhonelink)`
    ${commonIconStyle}
`;
export const ChiffreAffaireIcon=styled(MdWaterfallChart)`
    ${commonIconStyle}
`;
export const TruckIcon=styled(TbTruckOff)`
    ${commonIconStyle}
`;
export const ChartIcon=styled(FiPieChart)`
    ${commonIconStyle}
`;
export const EyeIcon=styled(FiEye)`
    ${commonIconStyle}
`;
export const SupportIcon=styled(BiSupport)`
    ${commonIconStyle}
`;
export const ShoppingIcon=styled(BiShoppingBag)`
    ${commonIconStyle}
`;
export const MapIcon=styled(BiMapAlt)`
    ${commonIconStyle}
`;
export const MoneyIcon=styled(GrMoney)`
    ${commonIconStyle}
`;
export const HandShakeIcon=styled(FaRegHandshake)`
    ${commonIconStyle}
`;


export const AvantagesContainer=styled.div`
    height:fit-content;
    padding: 5% 0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    @media screen and (max-width:960px){
        padding:10% 0;
    } 
`;


const rainbow_text_simple_animation_rev=keyframes`
      0% {
          background-size: 650%;
      }
      40% {
          background-size: 650%;
      }
      100% {
          background-size: 100%;
      }
  `;

const rainbow_text_simple_animation =keyframes`
      0% {
          background-size: 100%;
      }
      80% {
          background-size: 650%;
      }
      100% {
          background-size: 650%;
      }
`;


export const BoldSpan=styled.span`
    font-weight:900;
    font-family: "Kurye"; 
    font-size:2rem;

    background-color: #EAA1C6;
    
 
     background-image: linear-gradient(
          45deg,
          #EAA1A1 16.666%, 
          #EAA1AD 16.666%, 
          #EAA1BA 33.333%, 
          #EAA1C6 33.333%, 
          #EAA1D2 50%, 
          #A1CEB0 50%, 
          #A1D0B9 66.666%, 
          #A1CEBF 66.666%, 
          #A1CEC6 83.333%, 
          #A1CEBF 83.333%);
    
    background-size: 100%;
    background-repeat: repeat;
  
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 

    animation: ${rainbow_text_simple_animation_rev} 0.75s ease forwards;

    &:hover{
        animation: ${rainbow_text_simple_animation} 0.5s ease-in forwards;
    }
    @media screen and (max-width:480px){
        font-size:1.5rem;
    }
  
`;

 

export const Span=styled.span`
    font-weight:500;
    font-size:2rem;
    @media screen and (max-width:480px){
        font-size:1.5rem;
    }
  
`;


export const AvantageTitle=styled.p`
    font-size:1.5rem;
    font-weight: 700;
    
    @media screen and (max-width:480px){
    font-size:1.3rem;
    }
`;


export const AvantageH1=styled.h1`
    color:#0a0a0a;
    margin-bottom:2rem;
`;


export const Img=styled.img`
    width:80%;
    height:100%;
  
`;
