import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";

/*icons*/
export const IconContainer = styled.div`
  background: ${(props) => props.color};
  border-radius: 8px;
  padding: 2.5%;
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const commonIconStyle = css`
  height: 2rem;
  width: 2rem;
  color: #0a0a0a;
  @media screen and (max-width: 960px) {
    height: 1.5rem;
    width: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    height: 1.8rem;
    width: 1.8rem;
  }
`;
export const Icon = styled.img`
  width: 50px;
  margin: 2%;
`;

/*avantages*/

export const hoverCardStyle = css`
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;

export const AvantageH1 = styled.h1`
  color: #0a0a0a;
  margin-bottom: 3rem;
`;

export const AdvantageWrapper = styled.div`
  height: fit-content;
  width: 100%;
  display: column;
  align-items: start;
  justify-content: flex-start;
`;

export const AvantageText = styled.p`
  color: #0a0a0a;
  font-size: 1rem;
  line-height: 1.5;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export const AdvantageContent = styled.div`
  background: transparent;
  height: fit-content;

  padding: 1.5rem 0;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 2rem;

  ${hoverCardStyle}
`;

export const Shadow = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    background: linear-gradient(
      to right,
      #a1d0b9,
      #a9dad4,
      #bce2e8,
      #d3e9f4,
      #eaf1fa,
      #ebeffa,
      #eeecf9,
      #f1e9f7,
      #ecd8ee,
      #eac6e0,
      #eab4cf,
      #eaa1ba
    );
    position: absolute;
    height: 100%;
    width: 50%;
    z-index: -1;
    filter: blur(300px);
  }
`;

/*Button*/
export const Button = styled(Link)`
  border-radius: 2px;

  background: ${(props) => props.color};
  white-space: nowrap;

  padding: 0.9rem 1rem;
  margin: 0 1rem 0.5rem 0;

  color: ${({ dark }) => (dark ? "#fff ;" : "#0a0a0a;")};

  font-size: 0.9rem;

  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 0 0.5rem 0.5rem 0;
  }
  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    padding: 0.5rem 1.5rem;
    margin: 0.2rem 0;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 1rem;
  font-size: 1.2rem;
  color: ${({ white }) => (white ? "#fff ;" : "#0a0a0a;")};
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 1rem;
  font-size: 1.2rem;
  color: ${({ white }) => (white ? "#fff ;" : "#0a0a0a;")};
`;

export const BtnWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: 590px) {
    flex-direction: column;
  }
`;

/*Containers*/
export const Container = styled.div`
  position: relative;
  height: fit-content;
  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  @media screen and (max-width: 960px) {
    padding: 10% 5%;
  }
`;

export const leftRightContainersStyle = css`
  padding: 5% 5%;
  height: fit-content;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  justify-content: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 960px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    padding: 10% 5%;
  }
`;

export const LeftRightContainer = styled.div`
  ${leftRightContainersStyle};
  background: ${(props) => props.color};
  transition: background 0.4s ease;
  width: 100%;
`;

export const BannerContainer = styled.div`
  position: relative;
  background:${({ pink }) => (pink ? "#edafc5;" : "#b6eae1;")}
  padding:5% 10%;
  margin-top:2%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

`;

export const TextColumn = styled.div`
  padding: 5%;
  max-width: 90%;
  grid-area: col1;
  @media screen and (max-width: 960px) {
    max-width: 100%;
  }
`;

export const ImageColumn = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-area: col2;
  overflow: "hidden";
`;

/*Text*/
export const H1 = styled.h1`
  position: relative;
  color: ${({ white }) => (white ? "#fff" : "#0a0a0a")};
  font-size: 2.5rem;
  font-family: "Kurye";
  font-weight: 900;
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin-bottom: 1rem;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const Span = styled.span`
  font-size: 2.5rem;
  font-weight: 400;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const BoldSpan = styled.span`
  font-size: 2.5rem;
  font-weight: 900;
  font-family: "Kurye";

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const SectionH1 = styled.h1`
  color: #fff;
  text-align: left;
  font-family: "Kurye";
  margin-bottom: 1rem;
  text-align: ${({ center }) => (center ? "center" : "left")};
`;

export const SubTitle = styled.p`
  margin-bottom: 2rem;
  color: ${({ white }) => (white ? "#fff" : "#0a0a0a")};
  font-size: 1.1rem;
  font-weight: 400;
  text-align: ${({ center }) => (center ? "center" : "left")};
  line-height: 1.5;
  max-width: 700px;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
`;

export const SvgImg = styled.img`
  position: absolute;
  z-index: 0;
  height: calc(100% + 5px);
  width: calc(100% + 4px);
  top: -2.5px;
  right: -2.5px;
  bottom: -2.5px;
  left: -1.5px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;

/*Cards*/
export const cardsharedStyles = css`
  background-image: url("${(props) => props.img}");
  background-size: cover;
  color: #0a0a0a;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 5px;
  padding: 3rem;
`;
export const cardsWrappersharedStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 2rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
