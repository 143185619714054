import { useTranslation } from "react-i18next";
import { Container, Span, SubTitle } from "./AnnonceElements";

export const Annonce = () => {
  const { t } = useTranslation();

  return (
    <Container color="rgba(237, 76, 160,0.4)">
      <SubTitle center={1}>
        <Span>{t("annoncetext1")} </Span>{t("annoncetext2")}
      </SubTitle>
    </Container>
  );
};
