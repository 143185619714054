import styled from "styled-components";


export const NewsContainer=styled.div`
   
    height:fit-content;
    padding: 5% 5%;
    display:flex;
    background: #A1CEC6;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    @media screen and (max-width:960px){
      padding:10% 5%;
  } 


`;

export const NewsLetterForm = styled.form`
    position: relative;
    width:50%;
    align-items:center;
    margin:2% 0;
    @media screen and (max-width: 480px){
        width:90%;
        margin:1rem 0;
    }
`;
export const NewsLetterInput = styled.input`
    padding: 1rem;
    border-radius: 2px;
    height:50px;
    
    border:1px solid #A1CEC6 ;
    width:100%;
    @media screen and (max-width: 480px){
        padding:0 1rem;
        height:50px;
    }
    &:focus {
        outline: none !important;
        border: 1px solid #0a0a0a;
      
    }
      
`;
export const NewsLetterSubmit = styled.button`
    cursor: pointer;
    padding:0 3rem;

    background: #eab2c5;
    border: 1px solid #0a0a0a;
    color: #0a0a0a;
    font-weight:500;
   
    position: absolute;
    z-index: 2;
    top: 0;
    right:0;
    bottom:0;
    transform: translateX(2px);
    @media screen and (max-width: 480px){
        padding:0 2rem;
    }

`;

export const ValidationWrapper=styled.div`
    width:50%;
    display:flex;
    justify-content:center;
    padding: 1rem 2rem;
    margin: 0.5rem 0;
    background: #cdf2ea;
    color:#0a0a0a;
    border-radius:2px;
    @media screen and (max-width: 480px){
        width:90%;
      
    }
`;
export const ErrorWrapper=styled.div`
    width:50%;
    display:flex;
    justify-content:center;
    padding: 1rem 2rem;
    margin: 1rem 0;
    background: #f26b70;
    color:#fff;
    border-radius:2px;
    @media screen and (max-width: 480px){
        width:90%;
      
    }
    
`;
export const Message=styled.p`
    text-align:center;
    @media screen and (max-width:480px){
        font-size:0.8rem;
      
    }
    
`;
