import React from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import {
  CardWrapper,
  CardImg,
  CardLogo,
  CardTitle,
  CardSubTitle,
  TitleWrapper,
  CaracteristicWrapper,
  Dot,
  Caracteristic,
  CaracteristicsWrapper,
} from "./PartnersListElements";

const PartnerCard = (props) => {
  const currentLanguageCode= cookies.get("i18next") || "en"

  const { t } = useTranslation();
  return (
    <CardWrapper>
      <CardImg bg={props.cover}>
        <CardLogo bg={props.logo}></CardLogo>
      </CardImg>

      <TitleWrapper>
        <CardTitle>{props.name}</CardTitle>
        <CaracteristicsWrapper >
          <CaracteristicWrapper display={props.wilayas}>
            <Dot color="#e58b8b"></Dot>
            <Caracteristic color="#e58b8b">{t("instore")}</Caracteristic>
          </CaracteristicWrapper>
          <CaracteristicWrapper display={props.hasWebsite >= 1 ? 1 : 0}>
            <Dot color="#78bfb2"></Dot>
            <Caracteristic color="#78bfb2">{t("online")}</Caracteristic>
          </CaracteristicWrapper>
        </CaracteristicsWrapper>
      </TitleWrapper>
      <CardSubTitle>{currentLanguageCode==="en"?props.teaserEn:props.teaser}</CardSubTitle>
    </CardWrapper>
  );
};

export default PartnerCard;
