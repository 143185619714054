import React from "react";
import { useTranslation } from "react-i18next";
import "./values.css";
import { Container, H1 } from "../../SharedComponents/SharedComponents";
import {
  ValueCard,
  CardIdea,
  ValueWrapper,
  CardH2,
  CardHappy,
  CardMoney,
  CardReaction,
  CardP,
  IconContainer,
} from "./ValuesSectionElements";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Values = () => {
  const { t } = useTranslation();
  return (
    <AnimationOnScroll animateIn="animate__fadeIn" delay={2} animateOnce={1}>
      <Container>
        <H1 center={1}>{t('prizyvousapporte')}</H1>
        <ValueWrapper>
          <ValueCard className="delay-1" pink={0}>
            <IconContainer color="#89b6ff">
              <CardIdea></CardIdea>
            </IconContainer>
            <CardH2>{t('simplicite')}</CardH2>
            <CardP>
            {t('simplicite-subtitle')}
            </CardP>
          </ValueCard>

          <ValueCard className="delay-2" pink={1}>
            <IconContainer color="#f7f380">
              <CardHappy></CardHappy>
            </IconContainer>
            <CardH2>{t('satisfaction')}</CardH2>
            <CardP>
            {t('satisfaction-subtitle')}
            </CardP>
          </ValueCard>

          <ValueCard className="delay-3" pink={0}>
            <IconContainer color="#bee5cc">
              <CardMoney></CardMoney>
            </IconContainer>
            <CardH2>{t('reductioncouts')}</CardH2>
            <CardP>
            {t('reductioncouts-subtitle')}
            </CardP>
          </ValueCard>

          <ValueCard className="delay-4" pink={1}>
            <IconContainer color="#e9a1ce">
              <CardReaction></CardReaction>
            </IconContainer>
            <CardH2>{t('engagement')}</CardH2>
            <CardP>{t('engagement-subtitle')}</CardP>
          </ValueCard>
        </ValueWrapper>
      </Container>
    </AnimationOnScroll>
  );
};

export default Values;
