import img1 from '../../../assets/zerrouki.jpeg'
import img2 from '../../../assets/Salah_ANA_DHL.png'
import img3 from '../../../assets/zineb_emplotic.png'
import img8 from '../../../assets/yasmine.png'
import img4 from '../../../assets/clientLogos/lafarge.png'
import img5 from '../../../assets/clientLogos/emploitic.png'
import img6 from '../../../assets/clientLogos/dhl.png'
import img7 from '../../../assets/clientLogos/totalenergies.png'


export const SliderData=[

    {
        name:"Zerrouki Salah",
        role:"reviewer1-title",
        review:"reviewer1-review",
        picture:img1,
        entreprise:img4
    },
    {
        name:"Kaoua Zineb",
        role:"reviewer2-title",
        review:"reviewer2-review" ,
        picture:img3,
        entreprise:img5
    },
    {
        name:"Rachid Salah Gana",
        role:"reviewer3-title",
        review:"reviewer3-review",
        picture:img2,
        entreprise:img6
    },
    {
        name:"SALAMA Djamila",
        role:"reviewer4-title",
        review:"reviewer4-review",
        picture:img8,
        entreprise:img7
    },
    
]