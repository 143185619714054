import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageColumn,
  LeftRightContainer,
  H1,
  Span,
  Button,
  SubTitle,
  BtnWrapper,
  ArrowForward,
  ArrowRight,
  TextColumn,
} from "../../SharedComponents/SharedComponents";
import { Img } from "./HeroElements";

import img2 from "../../../assets/y.png";
import img1 from "../../../assets/z.png";
import img3 from "../../../assets/i.png";
import img4 from "../../../assets/r.png";
import img5 from "../../../assets/p.png";
import ReactGA from "react-ga4";

const HeroSection = () => {
  const [imgHero, setImg] = useState(img5);
  const [colorHero, setColor] = useState(
    "linear-gradient(45deg,#ffaebc,#e8a1d0);"
  );
  const [buttonHero, setButtonColor] = useState("#FFF6F3;");

  const { t } = useTranslation();

  useEffect(() => {
    const images = [img5, img4, img3, img1, img2];
    let index = 0;

    const changebackground = () => {
      setImg(images[index]);
      index = (index + 1) % images.length;
    };
    const interval = setInterval(() => {
      changebackground();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const colors = [
      "linear-gradient(45deg,#ffaebc,#e8a1d0);",
      "linear-gradient(45deg,#D6E5FE,#7bd6c9);",
      "linear-gradient(45deg,#CBE9D8,#7bd6c9);",
      "linear-gradient(45deg,#ffaebc,#e8a1d0);",
      "linear-gradient(45deg,#FAE8D0,#EAA1BA);",
    ];
    const buttonColors = [
      "#FFF6F3;",
      "#EAF7FE;",
      "#FFF3D8;",
      "#E9F4E6;",
      "#EEF8F0;",
    ];

    let indexc = 0;
    const changeColor = () => {
      setColor(colors[indexc]);
      setButtonColor(buttonColors[indexc]);
      indexc = (indexc + 1) % colors.length;
      console.log(indexc);
    };
    const interval = setInterval(() => {
      changeColor();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const [hover, setHover] = useState(false);
  const onHover = () => {
    ReactGA.event({
      category: "Landing Page hero",
      action: "Go to contact",
    });
    setHover(!hover);
  };
  return (
    <>
      <LeftRightContainer color={colorHero}>
        <TextColumn>
          <H1 className="animate" center={0}>
            <Span>{t("reinventer")}</Span>
            <div className="box">
              <ul className="wordSliderItems">
                <li className=" items item-1">{t("recompense")}</li>
                <li className="items item-2">{t("fedelisation")}</li>
                <li className="items item-3">{t("motivation")}</li>
                <li className=" items item-1">{t("recompense")}</li>
              </ul>
            </div>
            <Span>{t("en-entreprise")}</Span>
          </H1>

          <SubTitle className="animate" center={0}>
            {t("landingpage-hero-subtitle1")}
          </SubTitle>
          <SubTitle className="animate" center={0}>
            {t("landingpage-hero-subtitle2")}
          </SubTitle>
          <BtnWrapper className="animate">
            <Button
              to="/aideetcontact"
              color={"#0a0a0a"}
              dark={1}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t("devenirpartenaire")}
              {hover ? <ArrowRight white={1} /> : <ArrowForward white={1} />}
            </Button>
            <Button
              to="/aideetcontact"
              color={buttonHero}
              dark={0}
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t("devenirclient")}
              {hover ? <ArrowRight white={0} /> : <ArrowForward white={0} />}
            </Button>
          </BtnWrapper>
        </TextColumn>
        <ImageColumn className="animate">
          <Img img={imgHero} alt="Réinventer la récompense, la motivation et la fidélisation en entreprise avec Prizy"></Img>
        </ImageColumn>
      </LeftRightContainer>
    </>
  );
};

export default HeroSection;
