import styled from "styled-components";

export const CardPartnersWrapper=styled.div`
    display:flex;
    gap: 2rem;
    width:100%;
    overflow-x: auto;
    padding:1rem;
    scrollbar-width: none;
    -ms-overflow-style: none; 

    &::-webkit-scrollbar {
        display: none;
    }
 
    
`;

export const PartnersWrapper=styled.div`
    background:${props => props.color};
    display:flex;
    flex-direction:column;
    align-items:start;
    justify-content:flex-start;
    padding:5% 5%;
`;

export const PartnersTitle=styled.h3`
    font-size:1.5rem;
    font-weight:600;
    font-family:"Kurye";
    margin-bottom:1rem;
    margin-left:1rem;
`;

export const Img=styled.img`
    width:75%;
    border-radius:10px;
`;

