import React from "react";
import {
  ArticleContentContainer,
  ArticleParagraph,
  ArticleUl,
  Articleli,
  ArticleSubTitle,
} from "./BlogElements";
import { Container } from "../SharedComponents/SharedComponents";
import ArticleBanner from "./ArticleBanner";
import incentive from "../../assets/article7banner.png";
import ReactGA from "react-ga4";

const Article7 = () => {
  ReactGA.event({
    category: "Prizy: Votre allié pour des avantages salariés exceptionnels!",
    action: "Blog article",
  });
  return (
    <Container>
      <ArticleBanner
        title={"Prizy: Votre allié pour des avantages salariés exceptionnels!"}
        date={"13 Juillet 2023"}
        time={"4"}
        img={incentive}
      ></ArticleBanner>
      <ArticleContentContainer>
        <ArticleParagraph>
          Prizy, la solution ultime pour offrir des avantages salariés
          exceptionnels à vos employés ! Chez Prizy, nous comprenons
          l'importance de la satisfaction et de la motivation des employés pour
          la réussite d'une entreprise. C'est pourquoi nous avons développé une
          plateforme innovante de cartes cadeaux 100% digitales qui permet à vos
          employés de profiter d'une expérience inégalée.
        </ArticleParagraph>

        <ArticleSubTitle>
          Pourquoi les avantages salariés sont-ils si importants ?
        </ArticleSubTitle>
        <ArticleParagraph>
          Offrir des avantages salariés attractifs est essentiel pour attirer et
          retenir les meilleurs talents, ainsi que pour créer un environnement
          de travail dynamique et productif. Les employés qui se sentent
          valorisés et récompensés sont plus susceptibles d'être engagés,
          motivés et fidèles à leur entreprise. Les avantages salariés peuvent
          également contribuer à renforcer l'esprit d'équipe, à stimuler la
          collaboration et à favoriser un climat de travail positif.
        </ArticleParagraph>
        <ArticleSubTitle>
          Prizy : Votre allié pour des avantages salariés exceptionnels !
        </ArticleSubTitle>
        <ArticleParagraph>
          Prizy est là pour vous aider à offrir des avantages salariés qui
          feront sourire vos employés. Nos cartes cadeaux digitales offre une
          vaste sélection de partenaires dans différents secteurs tels que le
          prêt-à-porter, les accessoires, les électroménagers, l'électronique,
          les hypermarchés, la puériculture et la téléphonie mobile… Vos
          employés auront ainsi un large choix pour utiliser leurs cartes
          cadeaux et s'offrir ce qui leur plaît le plus.
        </ArticleParagraph>
        <ArticleSubTitle>
          Les avantages de Prizy pour votre entreprise :
        </ArticleSubTitle>
        <ArticleUl>
          <Articleli>
            <b>Fidélisation et motivation accrues :</b> En offrant des avantages
            salariés attractifs, vous renforcez le lien entre vos employés et
            votre entreprise. Les cartes cadeaux Prizy sont un moyen
            incontournable de fidéliser et de motiver vos équipes.
          </Articleli>
          <Articleli>
            <b>Augmentation du pouvoir d'achat :</b> Les cartes cadeaux Prizy
            permettent d'accroître le pouvoir d'achat de vos employés, de vos
            partenaires et de toutes les parties prenantes de votre entreprise.
            Cela contribue à améliorer leur qualité de vie et à renforcer leur
            engagement envers votre entreprise.
          </Articleli>
          <Articleli>
            <b>Flexibilité et simplicité :</b> Avec les cartes cadeaux digitales
            Prizy, vos employés peuvent facilement les utiliser en ligne ou en
            magasin, selon les partenaires figurant dans le catalogue. La
            flexibilité offerte par notre plateforme facilite l'accès aux
            avantages salariés pour tous.
          </Articleli>
          <Articleli>
            <b>Personnalisation :</b> Prizy vous permet de personnaliser les
            avantages salariés en fonction des besoins et des préférences de vos
            employés. Vous pouvez choisir les partenaires et les montants des
            cartes cadeaux en fonction de vos objectifs et de votre budget.
          </Articleli>
        </ArticleUl>

        <ArticleParagraph>
          Ne manquez pas l'occasion de maximiser le bonheur et la satisfaction
          de vos employés grâce aux cartes cadeaux Prizy.
        </ArticleParagraph>
        <ArticleParagraph>
          Contactez-nous dès aujourd'hui pour en savoir plus sur nos solutions
          et pour démarrer votre voyage vers des employés plus motivés et plus
          engagés !
        </ArticleParagraph>
      </ArticleContentContainer>
    </Container>
  );
};

export default Article7;
