import styled from "styled-components";


export const ClientsWrapper=styled.div`
    
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items:center;
    justify-content:center;
    width:fit-content;
    grid-gap: 0;

    outline: 2px solid #fff;
	outline-offset: -0.5px;
    
    @media screen and (max-width:480px){
        grid-template-columns: 1fr 1fr;
    }
`;
export const CientLogo=styled.div`
    width:150px;
    height:114px;
    align-self: center;   
    background:url(${props=>props.img});
    background-size: contain;
    background-repeat: no-repeat;
    transition: background 0.4s ease;
    background-color:white;

    outline: 0.5px dashed #ccc;

    &:hover{
        transform: scale(1.5);
    }



`;

