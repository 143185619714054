import React from 'react'
import {ArticleContentContainer,ArticleParagraph,ArticleSubTitle,ArticleUl,Articleli} from './BlogElements'
import {Container} from '../SharedComponents/SharedComponents'
import ArticleBanner from './ArticleBanner'
import motivez from '../../assets/motivezbanner.png'
import ReactGA from "react-ga4";

const Article1 = () => {
  ReactGA.event({
    category: "Motivez & Fidélisez vos collaborateurs",
    action: "Blog article",
  });
  return (
    <Container>
        <ArticleBanner title={"Motivez & Fidélisez vos collaborateurs"} date={"14 Décembre 2022"} time={"5"} img={motivez}></ArticleBanner>
        <ArticleContentContainer>
            <ArticleParagraph>La motivation et la fidélisation de vos équipes sont des enjeux managériaux et RH de premier plan.</ArticleParagraph>
            <ArticleParagraph>La solution de carte cadeau Prizy vous permettra à coup sûr de faire plaisir à l'ensemble de vos collaborateurs, de vos employés et même de vos partenaires.</ArticleParagraph>
            <ArticleSubTitle>LA CARTE CADEAU AU PLUS LARGE CHOIX D'ENSEIGNES</ArticleSubTitle>
            <ArticleParagraph>Notre large réseau de partenaires allant du prêt-à-porter, accessoires, cosmétiques, électroménagers, électronique, puériculture, salles de sports, décoration aux hypermarchés permettra de faire vivre une expérience hors du commun à vos collaborateurs.</ArticleParagraph>
            <ArticleParagraph>Peu importe où se trouvent vos collaborateurs, nos cartes cadeaux les enchanteront grâce à nos 500 magasins partenaires sur le territoire national.</ArticleParagraph>
            <ArticleSubTitle>Prizy : SIMPLE POUR LES BÉNÉFICIAIRES COMME POUR LES DÉCIDEURS</ArticleSubTitle>
            <ArticleParagraph>Pour les bénéficiaires, l'utilisation est simple, ils reçoivent leurs codes QR ainsi que leurs codes secrets par email et peuvent utiliser leurs cartes cadeaux chez l'ensemble des magasins partenaires.</ArticleParagraph>
            <ArticleParagraph>Il peuvent aussi se faire livrer à domicile où qu'ils se trouvent sur le territoire national en passant commande chez nos sites web partenaires.</ArticleParagraph>
            <ArticleParagraph>Pour les décideurs, ils n'ont qu'à désigner les montants à envoyer à leurs collaborateurs et l'envoi se fait en un seul clic.</ArticleParagraph>
            <ArticleSubTitle>EN BREF, Prizy  C'EST :</ArticleSubTitle>
            <ArticleUl>
                <Articleli>Des cartes cadeaux dématérialisées</Articleli>
                <Articleli>Multi-enseignes et multi-catégories</Articleli>
                <Articleli>Envoi en un seul clic</Articleli>
                <Articleli>Une solution qui permet la motivation et la fidélisation des employés</Articleli>
            </ArticleUl>
        </ArticleContentContainer>
      
    </Container>
  )
}

export default Article1
