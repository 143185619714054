import React,{useState} from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import ReactGA from 'react-ga4'
import { useTranslation } from "react-i18next";

import { LeftRightContainer,Button,BtnWrapper,H1,SubTitle,ArrowForward,ArrowRight,TextColumn,ImageColumn, BoldSpan} from '../../SharedComponents/SharedComponents'
import {Logo} from './SolutionElements'
import './Solution.css'
import video from "../../../assets/videos/beneficiaire.mp4"
import logo from '../../../assets/logo_prizy.png'


const SolutionSection = () => {
 
  const  [hover,setHover]=useState(false)
  const onHover=()=>{
      setHover(!hover)
     
      ReactGA.event({
        category: 'Solution Section From Landing Page',
        action: 'Go to solution',
       
      });
      
  }
  const { t } = useTranslation();
  
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
                <LeftRightContainer color={"#f9f9fb"} imgStart={0}>
                    <TextColumn>

                        <H1 center={0}><Logo src={logo} alt='Logo Prizy'></Logo><BoldSpan>{t('landingpage-solution-title')}</BoldSpan></H1>
                        <SubTitle center={0}>{t('landingpage-solution-subtitle1')}</SubTitle>
                        <SubTitle center={0}>{t('landingpage-solution-subtitle2')}</SubTitle>
                        <SubTitle center={0}>{t('landingpage-solution-subtitle3')}</SubTitle>
                       
                        <BtnWrapper>      
                            <Button color={'#0a0a0a'} dark={1} to="/solution" onMouseEnter={onHover} onMouseLeave={onHover}>
                                {t('savoirplus')} { hover? <ArrowRight white={1}/>:<ArrowForward white={1}/>}
                            </Button>
                        </BtnWrapper>
                    </TextColumn>
                    <ImageColumn>      
                        <video playsinline autoplay="autoplay" loop muted src={video} type='video/mp4' width='500' alt="Description de Prizy en tant que solution"></video>   
                    </ImageColumn>
    
                </LeftRightContainer>
    </AnimationOnScroll>
  )
}

export default SolutionSection;
