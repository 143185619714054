import styled, {css} from 'styled-components'
import {FiPhone,FiMapPin} from 'react-icons/fi'
import {MdAlternateEmail} from 'react-icons/md'

const inputssharedStyle = css`
    padding: 1rem;
    border-radius: 2px;
    border: 1px solid #ccc;
    width:100%;
    @media screen and (max-width:480px){
        padding: 0.8rem;
    }

    &:focus {
        outline: none !important;
        border: 1.5px solid #0a0a0a;
      
    }
      
`;

const iconssharedStyle=css`
    font-size:1rem;
    color:#0a0a0a;
`;


export const Input=styled.input`
    ${inputssharedStyle}
`;
export const TextArea=styled.textarea`
    ${inputssharedStyle}
`;
export const Select=styled.select`
    ${inputssharedStyle}
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
        linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        radial-gradient(#EAA1AD 70%, transparent 72%);
    background-position:
        calc(100% - 25px) calc(1em + 7px),
        calc(100% - 20px) calc(1em + 7px),
        calc(100% - .7em) .6em;
    background-size:
        5px 5px,
        5px 5px,
        2em 2em;
    background-repeat: no-repeat;

    @media screen and (max-width:480px){
        background-position:
        calc(100% - 24px) calc(1em + 6px),
        calc(100% - 19px) calc(1em + 6px),
        calc(100% - .6em) .5em;
    }
`;
export const Label=styled.label`

    margin-bottom: 1rem;
    display: block;
    width:100%;
`;
export const ContactButton=styled.button`

    cursor: pointer;
    padding: 1rem 2rem;
    background: #0a0a0a;
    color:#fff;
    border:none;
    border-radius: 2px;
    width:100%;
    font-weight:500;

  
`;
export const Controls=styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
`;
export const InputWrapper=styled.div`
    width:100%;
    
    
`;
export const ValidationWrapper=styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    padding: 1rem 2rem;
    margin: 1rem 0;
    background: #A1CEC6;
    color:#0a0a0a;
    border-radius:2px;
`;
export const ErrorWrapper=styled.div`
    display:flex;
    justify-content:center;
    padding: 1rem 2rem;
    margin: 1rem 0;
    background: #f26b70;
    color:#fff;
    border-radius:2px;
    
`;

export const Message=styled.p`
    text-align:center;
    @media screen and (max-width:480px){
        font-size:0.8rem;
      
    }
    
`;
export const Form=styled.form`
    width:90%;
`;




export const ContactWrapper=styled.div`
    background:transparent;
    height:fit-content;
    margin-top:1rem;
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items:center;
`;

export const ContactText=styled.p`
    color: #0a0a0a;
    font-size:1rem;
    line-height:1.5;

    @media screen and (max-width:480px){
        font-size:0.8rem;
    }
`;
export const EmailIcon=styled(MdAlternateEmail)`
    ${iconssharedStyle}
`;
export const PhoneIcon=styled(FiPhone)`
    ${iconssharedStyle}
`;
export const MapPinIcon=styled(FiMapPin)`
    ${iconssharedStyle}
`;

export const ContactContent =styled.div`
    background:transparent;
    height:fit-content;
    
    padding : 0.8rem 0;
    width:100%;
    display: flex;
    justify-content: start;
    align-items:center;
    gap:2rem;

    @media screen and (max-width:480px){
        padding : 0.5rem 0;
    }




`;






