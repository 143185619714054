import React from "react";
import { useTranslation } from "react-i18next";
import {
  AvantageText,
  H1,
  SubTitle,
  TextColumn,
  ImageColumn,
  LeftRightContainer,
  IconContainer,
} from "../SharedComponents/SharedComponents";
import ContactForm from "./ContactForm";
import {
  ContactContent,
  PhoneIcon,
  MapPinIcon,
  EmailIcon,
} from "./ContatElements";

const ContactSection = () => {
  const { t } = useTranslation();

  return (
    <LeftRightContainer imgStart={0}>
      <TextColumn className="animate">
        <H1>{t('contact-title')}</H1>
        <SubTitle>
        {t('contact-subtitle')}
        </SubTitle>

        <ContactContent>
          <IconContainer color="#ffd9ed">
            <MapPinIcon />
          </IconContainer>
          <AvantageText>{t('adresse')} : N°1 Cité Yassmine Draria، 16000</AvantageText>
        </ContactContent>

        <ContactContent>
          <IconContainer color="#EAA1AD">
            <EmailIcon />
          </IconContainer>
          <AvantageText>contact@prizy.co</AvantageText>
        </ContactContent>

        <ContactContent>
          <IconContainer color="#b0e0d6">
            <PhoneIcon />
          </IconContainer>
          <AvantageText>(+213) 550 57 66 44</AvantageText>
        </ContactContent>
      </TextColumn>
      <ImageColumn className="animate">
        <ContactForm></ContactForm>
      </ImageColumn>
    </LeftRightContainer>
  );
};

export default ContactSection;
