import React from "react";
import { useTranslation } from "react-i18next";
import "../../Solutions/ValuesSection/values.css";
import {
  Container,
  H1,
  SubTitle,
} from "../../SharedComponents/SharedComponents";
import {
  ValueCard,
  ValueWrapper,
  CardH2,
  CardMoney,
  CardP,
  IconContainer,
  CardStores,
  CardTransactions,
} from "./AdvantagesElements";
import { AnimationOnScroll } from "react-animation-on-scroll";

const AdvantagesSection = () => {
  const { t } = useTranslation();
  return (
    <AnimationOnScroll animateIn="animate__fadeIn" delay={2} animateOnce={1}>
      <Container>
        <H1 center={1}>{t("prizyavantagestitle")}</H1>
        <SubTitle center={1}>{t("prizyavantagessubtitle")}</SubTitle>
        <ValueWrapper>
          <ValueCard className="delay-1" pink={0}>
            <IconContainer color="#cdfae3">
              <CardMoney></CardMoney>
            </IconContainer>
            <CardH2>{t("appavantage1title")}</CardH2>
            <CardP>{t("appavantage1subtitle")}</CardP>
          </ValueCard>

          <ValueCard className="delay-2" pink={1}>
            <IconContainer color="#f9d3ff">
              <CardStores></CardStores>
            </IconContainer>
            <CardH2>{t("appavantage2title")}</CardH2>
            <CardP>{t("appavantage2subtitle")}</CardP>
          </ValueCard>

          <ValueCard className="delay-3" pink={0}>
            <IconContainer color="#c9faf8">
              <CardTransactions></CardTransactions>
            </IconContainer>
            <CardH2>{t("appavantage3title")}</CardH2>
            <CardP>{t("appavantage3subtitle")}</CardP>
          </ValueCard>
        </ValueWrapper>
      </Container>
    </AnimationOnScroll>
  );
};

export default AdvantagesSection;
