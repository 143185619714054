import styled from 'styled-components';

export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  height: fit-content;
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  top: 30%;
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.1);
`;

export const Wrap = styled.div`
  border-bottom: 1px solid #f9f9f9;
  color: #0a0a0a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:5%;
  width: auto;
  cursor: pointer;
`;

export const Dropdown = styled.div`
  color: #0a0a0a;
  border-bottom: 1px solid #f9f9f9;
  background: #f7f7f7;
  height: fit-content;
  width: auto;
  display: flex;
  padding:5%;
  flex-direction: column;
  
`;

export const Span=styled.span`
    margin:0 1rem;
    font-size:1rem;
    font-weight: 400;
    @media screen and (max-width:960px){
    font-size:0.9rem;
    }

`;

export const BoldSpan=styled.span`
    margin:0 1rem;
    font-size:1rem;
    font-weight: 500;
    @media screen and (max-width:960px){
      font-size:0.9rem;
    }

   
`;








