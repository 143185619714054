import styled from "styled-components";
import { leftRightContainersStyle } from "../SharedComponents/SharedComponents";

export const  SolutionContainer=styled.div`
    margin: 0 10%;
    ${leftRightContainersStyle};
    background:${({pink})=>(pink?"#FAF3F3":"#F5FAF8")};
    @media screen and (max-width:480px){
        margin: 0;
    } 
    
`;

export const  PrizyProContainer=styled.div`
    margin: 0 10%;
    ${leftRightContainersStyle};
    padding-right:0;
    background:${({pink})=>(pink?"#FAF3F3":"#F5FAF8")};
    @media screen and (max-width:480px){
        margin: 0;
    } 
    
`;

export const ImageColumnWithoutMargins=styled.div`
    margin-left:5%;
    grid-area:col2;
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
`;

export const Img=styled.img`
    width:50%;
`;

export const ImgContact=styled.img`
    width:80%;
`;
export const ImgPrizyPro=styled.img`
    width:80%;
`;


