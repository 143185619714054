import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import img from "../../../assets/bloc3.png";
import { Img } from "../AdvantageElements";
import {
  LeftRightContainer,
  TextColumn,
  ImageColumn,
  H1,
  BoldSpan,
  SubTitle,
  BtnWrapper,
  Button,
  ArrowForward,
  ArrowRight,
} from "../../SharedComponents/SharedComponents";
import { AnimationOnScroll } from "react-animation-on-scroll";


const AvantageThree = () => {
 
  const { t } = useTranslation();

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <AnimationOnScroll
        animateIn="animate__fadeInUp"
        delay={2}
        animateOnce={1}
      >
        <LeftRightContainer imgStart={0}>
          <TextColumn>
            <H1 center={0}>
              <BoldSpan>{t('suiviconsommations')}</BoldSpan>
            </H1>
            <SubTitle>
              {t('prizypro-avantage3-subtitle')}
            </SubTitle>
            <BtnWrapper>
              <Button
                to="/aideetcontact"
                color="#e8a1d0"
                dark={0}
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              >
                {t('join')}
                {hover ? <ArrowRight white={0} /> : <ArrowForward white={0} />}
              </Button>
            </BtnWrapper>
          </TextColumn>
          <ImageColumn>
            <Img src={img} alt={t('suiviconsommations')+ " avec Prizy Pro by Prizy"}></Img>
          </ImageColumn>
        </LeftRightContainer>
      </AnimationOnScroll>
    </>
  );
};

export default AvantageThree;
