import React from "react";
import AdvantagesSection from "../components/MobileApp/AdvantagesSection";
import DownloadAppSection from "../components/MobileApp/DownloadApp";
import FunctionnalitiesSection from "../components/MobileApp/FunctionnalitiesSection";
import AppHero from "../components/MobileApp/HeroSection";

const MobileApp = () => {
  return (
    <>
      <AppHero></AppHero>
      <AdvantagesSection></AdvantagesSection>
      <FunctionnalitiesSection></FunctionnalitiesSection>
      <DownloadAppSection></DownloadAppSection>
    </>
  );
};

export default MobileApp;
