
import styled,{css}from "styled-components";
import img from "../../../assets/appbackground.png";

export const Img=styled.img`
    width:70%;
`;

export const leftRightContainersStyle=css`
    padding:0% 5%;
    height:fit-content;
    display:grid;
    grid-auto-columns:minmax(auto,1fr);
    align-items:center;
    justify-content:center;
    grid-template-areas:${({imgStart})=>(imgStart?`'col2 col1'`:`'col1 col2'`)};

    @media screen and (max-width:960px){
        grid-template-areas:${({imgStart})=>(imgStart?`'col1' 'col2'`:`'col1 col1' 'col2 col2'`)};
        padding:10% 5%;
    } 

`;

export const LeftRightContainer=styled.div`
    ${leftRightContainersStyle};
    background:${props => props.color};
    background-image: url("${img}");
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 0.4s ease;
    width:100%;
`;


