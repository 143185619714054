import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LeftRightContainer,
  H1,
  SubTitle,
  TextColumn,
  BtnWrapper,
  Button,
  ArrowForward,
  ArrowRight,
} from "../../SharedComponents/SharedComponents";
import { ImgPrizyPro, ImageColumnWithoutMargins } from "../SolutionElements";
import img from "../../../assets/prizyprobloc.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactGA from "react-ga4";

const PrizyProSection = () => {
  
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const onHover = () => {
    ReactGA.event({
      category: "Prizy Pro Section from Solution Page",
      action: "Go to Prizy Pro page",
    });
    setHover(!hover);
  };
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={2} animateOnce={1}>
      <LeftRightContainer imgStart={0} pink={0}>
        <TextColumn>
          <H1 center={0}>{t("solutionpage-prizypro")}</H1>
          <SubTitle center={0}>{t("solutionpage-prizypro-subtitle1")}</SubTitle>
          <SubTitle center={0}>{t("solutionpage-prizypro-subtitle2")}</SubTitle>
          <SubTitle center={0}>{t("solutionpage-prizypro-subtitle3")}</SubTitle>
          <BtnWrapper>
            <Button
              color={"#0a0a0a"}
              dark={1}
              to="/prizyPro"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
            >
              {t("savoirplus")}
              {hover ? <ArrowRight white={1} /> : <ArrowForward white={1} />}
            </Button>
          </BtnWrapper>
        </TextColumn>
        <ImageColumnWithoutMargins>
          <ImgPrizyPro src={img} alt="Prizy Pro, une offre de Prizy"></ImgPrizyPro>
        </ImageColumnWithoutMargins>
      </LeftRightContainer>
    </AnimationOnScroll>
  );
};

export default PrizyProSection;
