import React from "react";
import {
  BlogButton,
  BlogBtnWrapper,
  BlogSectionWrapper,
  BlogSectionTitle,
  BlogSectionSubTitle,
  BlogSectionCard,
} from "./BlogElements";
import { blogPartialData } from "./BlogData";
import { Link } from "react-router-dom";


const LandingPageAbstractComponents = () => {

  return (
    <BlogSectionWrapper className="animate">
      {blogPartialData.map((data, index) => {
        
        return (
          <Link to={data.path} style={{ textDecoration: "none" } }>
            <BlogSectionCard img={data.image}>
              <BlogSectionTitle>{data.title}</BlogSectionTitle>
              <BlogSectionSubTitle>{data.abstract}</BlogSectionSubTitle>
              <BlogSectionSubTitle>
                {data.time} min de lecture
              </BlogSectionSubTitle>

              <BlogBtnWrapper>
                <BlogButton to={data.path}>Lire la suite</BlogButton>
              </BlogBtnWrapper>
            </BlogSectionCard>
          </Link>
        );
      })}
    </BlogSectionWrapper>
  );
};

export default LandingPageAbstractComponents;
