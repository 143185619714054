import React from 'react'
import { useTranslation } from "react-i18next";
import CardsSection from '../components/Offers/CardsSection';
import ForWhoSection from '../components/Offers/ForWhoSection';
import OffersHero from '../components/Offers/OffersHero';
import JoinUsBanner from '../components/SharedComponents/JoinUsBanner';

const Offers=()=> {

  const { t } = useTranslation();
  return (
    <>
        <OffersHero></OffersHero>
        <CardsSection></CardsSection>
        <ForWhoSection></ForWhoSection>
        <JoinUsBanner pink={1} sousTitre={t('integrateclients')} buttonText={t('devenirclient')}></JoinUsBanner>
    </>
  )
}

export default Offers;